// src/BackendProxy/adminProxy/studentApi.js
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

// Remove a student from a course
export const removeStudent = async (studentId, courseId, removeAllCourses) => {
  try {
    const response = await axios.post(`${API_URL}api/course/remove-student-from-course`, {
      studentId,
      courseId,
      removeAllCourses,
    });
    return response.data;
  } catch (error) {
    console.error("Error removing student:", error);
    throw error;
  }
};

export const removeStudentsBulk = async (studentIds, courseId, removeAllCourses) => {
  try {
    const response = await axios.post(`${API_URL}api/course/remove-students-from-course`, {
      studentIds,
      courseId,
      removeAllCourses,
    });
    return response.data;
  } catch (error) {
    console.error("Error bulk removing students:", error);
    throw error;
  }
};

// Download student grades
export const downloadStudentGrades = async (studentIds, courseTitle) => {
  try {
    const response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/course/get-all-grades',
        {
          studentIds: studentIds,
          courseTitle: courseTitle || null,
        }
      );

    return response;
  } catch (error) {
    console.error("Error downloading grades:", error);
    throw error;
  }
};

export const updateEnrollmentVisibilityMulti = async (updates) => {
    try {
      const response = await axios.post(`${API_URL}api/course/update-multiple-enrollments`, {
        updates,
      });
      return response.data;
    } catch (error) {
      console.error("Error updating visibility:", error);
      throw error;
    }
  };

  export const updateSingleEnrollmentVisibility = async (enrollmentId, visibility) => {
    try {
      const response = await axios.post(
        `${API_URL}api/course/update-enrollment/${enrollmentId}`,
        { visible: visibility }
      );
      return response.data;
    } catch (error) {
      console.error("Error updating single enrollment visibility:", error);
      throw error;
    }
  };
