import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import GeneralNavbar from "../../../../components/navbar/GeneralNavbar";
import BlobComposition from "../../../../components/backgrounds/BlobComposition/BlobComposition";
import { SiGooglesheets } from "react-icons/si";
import axios from "axios";
import Pagination from "../../Profile/Pagination";
import DialogBox from "../../dialog/DialogBox";
import ConfirmationDialog from "../../dialog/ConfirmationDialog";
import { IoMdSearch } from "react-icons/io";
import SpinnerLoader from "../../../../components/loaders/SpinnerLoader";
import { fetchUploadedFiles, uploadFile, deleteFiles } from "../../../../BackendProxy/invitationAPI";

const AdminInvitationPage = () => {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFiles, setSelectedFiles] = useState([]); // Track selected files for deletion
  const itemsPerPage = 2;
  const [totalPages, setTotalPages] = useState(1);
  const authUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState(""); 
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [initialLoading, setInitialLoading] = useState(true);
  const [isInitial, setInitial] = useState(true);
      

  const [isConfirmDialogOpen, setConfirmIsDialogOpen] = useState(false);

  const openDialog = (message) => {
    setCurrentMessage(message);
    setIsDialogOpen(true);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  useEffect(() => {
    loadFiles(currentPage)
  }, [currentPage, searchTerm]); 

  const loadFiles = async (page) => {
    try {
      if (isInitial) setInitialLoading(true);
      
      // Fetch files with the current page and search term
      const data = await fetchUploadedFiles(authUser, page, searchTerm);

      setFiles(data.files);
      setTotalPages(data.totalPages);
      setCurrentPage(page);  // Correctly updating the page
      setInitial(false);
      setInitialLoading(false);
      
    } catch (error) {
      openDialog('Failed to fetch files.');
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      (selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        selectedFile.type === "application/vnd.ms-excel")
    ) {
      setFile(selectedFile);
      console.log("File selected:", selectedFile);
    } else {
      openDialog("Please upload a valid Excel file (.xlsx or .xls)")
    }
    e.target.value = null;
  };

  const handleUpload = async () => {
    try {
      await uploadFile(file, authUser);  // Call proxy function for upload
      openDialog("File uploaded successfully!");

      setFile(null);
      setCurrentPage(1);
      loadFiles(1); // Refetch files after upload
    } catch (error) {
      openDialog(`Failed to upload file: ${error}`);
    }
  };


  const handleDeleteSelected = async () => {
    if (selectedFiles.length === 0) return;

    try {
      await deleteFiles(selectedFiles);
      openDialog("Selected files deleted successfully.");
      setSelectedFiles([]);

      const data = await fetchUploadedFiles(authUser, currentPage);
      if (data.files.length === 0 && currentPage > 1) {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
        await loadFiles(currentPage - 1); 
      } else {
        setFiles(data.files);
        setTotalPages(data.totalPages);
      }
    } catch (error) {
      openDialog(`Failed to delete files: ${error}`);
    }
  };
  
  
  
  const toggleFileSelection = (fileId) => {
    setSelectedFiles((prevSelectedFiles) =>
      prevSelectedFiles.includes(fileId)
        ? prevSelectedFiles.filter((id) => id !== fileId)
        : [...prevSelectedFiles, fileId]
    );
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);  
    setCurrentPage(1);              
  };

  const currentFiles = files;

  return (
    <div className="relative h-full">
      <GeneralNavbar />
      <BlobComposition
        blobsData={[
          { top: "10%", left: "-20%", size: "700px" },
          { top: "-30%", left: "70%", size: "400px" },
          { top: "40%", left: "50%", size: "300px" },
        ]}
      />

      <div className="m-auto max-w-[1200px] mt-3 min-h-[100vh]">
        <div className="bg-white rounded-full flex justify-between items-center py-2 px-4">
          <p className="font-semibold text-lg">Uploaded Files</p>
          
    
      <div className="flex items-center space-x-3">
                  <div className="flex items-center">
                    <input
                      placeholder="Search by name"
                      className="text-sm focus:outline-none focus:border-b-stone-400 border-b-transparent border-b-[1.5px] pr-2 py-1 font-medium text-stone-600"
                      value={searchTerm}
                      onChange={handleSearchChange} 
                    />
                    <IoMdSearch />
                  </div>
           
            <label className="flex items-center hover:bg-green-50 bg-green-100 cursor-pointer px-3 py-1 hover:scale-[1.05] transition-all rounded-full">
              <span className="text-sm mr-1 font-medium text-green-500">
                Upload Excel File
              </span>
              <input
  type="file"
  key={file ? file.name : "default"}
  onChange={handleFileChange}
  accept=".xlsx, .xls"
  style={{ display: "none" }}
/>
              <SiGooglesheets className="text-green-500" />
            </label>
            <button
              onClick={handleUpload}
              disabled={!file}
              className={`${
                file
                  ? "bg-green-500 hover:bg-green-600"
                  : "bg-green-300 cursor-not-allowed"
              } text-white px-4 py-1 rounded-full font-medium transition-all`}
            >
              Upload
            </button>
            <button
  onClick={() => setConfirmIsDialogOpen(true)}
  disabled={selectedFiles.length === 0} // Disable if no files are selected
  className={`text-white px-4 py-1 rounded-full font-medium transition-all ${
    selectedFiles.length > 0 ? "bg-red-500 hover:bg-red-600" : "bg-gray-300 cursor-not-allowed"
  }`}
>
  Delete
</button>
          </div>
        </div>

        <div className="bg-white py-2 px-4 my-3 rounded-lg">
  <table className="table-auto w-full">
  <thead>
  <tr>
    <th className="w-[40px] text-left">
      {initialLoading ? (
        <input
          type="checkbox"
          disabled
          className="opacity-0" // Invisible checkbox
        />
      ) : (
        <input
          type="checkbox"
          onChange={(e) => {
            const currentPageFileIds = currentFiles.map((file) => file._id);
            if (e.target.checked) {
              setSelectedFiles((prevSelectedFiles) => [
                ...prevSelectedFiles,
                ...currentPageFileIds.filter(
                  (id) => !prevSelectedFiles.includes(id)
                ),
              ]);
            } else {
              setSelectedFiles((prevSelectedFiles) =>
                prevSelectedFiles.filter(
                  (id) => !currentPageFileIds.includes(id)
                )
              );
            }
          }}
          checked={
            currentFiles.length > 0 &&
            currentFiles.every((file) => selectedFiles.includes(file._id))
          }
        />
      )}
      </th>
        <th className="text-left">File Name</th>
        <th className="w-1/4 pl-20">Uploaded On</th>
        <th className="w-1/4 pl-20">Total Entries</th>
        <th className="w-1/4 pl-20"></th>
      </tr>
    </thead>

    <tbody>
      {initialLoading ? (
        <tr>
          <td colSpan="4" className="py-4 text-center">
            <SpinnerLoader />
          </td>
        </tr>
      ) : currentFiles.length > 0 ? (
        currentFiles.map((file, index) => (
          <tr
            key={index}
            className={`border-b ${
              selectedFiles.includes(file._id) ? "bg-gray-200" : ""
            }`}
          >
            <td>
              <input
                type="checkbox"
                checked={selectedFiles.includes(file._id)}
                onChange={() => toggleFileSelection(file._id)}
              />
            </td>
            <td className="py-2">{file.fileName}</td>
            <td className="py-2 pl-20">{new Date(file.uploadedOn).toLocaleString()}</td>
            <td className="py-2 pl-20">{file.preUsers ? file.preUsers.length : 0}</td>
            <td className="py-2 pl-20">
              <button
                onClick={() =>
                  navigate(`/uploads/${file._id}`, {
                    state: { fileName: file.fileName },
                  })
                }
                className="bg-blue-500 text-white px-3 py-1 rounded-full font-medium transition-all hover:bg-blue-600 hover:scale-[1.05]"
              >
                View Content
              </button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5" className="py-4 text-center">
            No files uploaded yet.
          </td>
        </tr>
      )}
    </tbody>
  </table>

          {files.length > 0  && (
  <Pagination
    totalPages={totalPages}
    currentPage={currentPage}
    onPageChange={(page) => setCurrentPage(page)}
  />
)}
        </div>
{/*
        {files.length === 0 && (
          <div className="text-center py-4 text-gray-500">No files uploaded yet.</div>
        )}
*/}
<ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        onClose={() => setConfirmIsDialogOpen(false)}
        onConfirm={handleDeleteSelected}
        message="Are you sure you want to delete this item? This action cannot be undone."
      />
      </div>
      <DialogBox
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        message={currentMessage}
      />
    </div>
  );
};

export default AdminInvitationPage;
