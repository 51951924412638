import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const fetchUploadedFiles = async (authUser, page = 1, searchTerm = '') => {
  try {
    const response = await axios.get(`${API_URL}api/preUser/files`, {
      params: {
        role: authUser.accountType,
        institutionCode: authUser.institution.code,
        page,
        limit: 5,
        searchTerm,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching files:', error);
    throw error;
  }
};

export const uploadFile = async (file, authUser) => {
    if (!file) throw new Error("Please select a file first.");
    
    const formData = new FormData();
    formData.append("file", file);
  
    const institutionCode = authUser?.institution?.code;
  
    if (!institutionCode) {
      throw new Error("Institution code is required");
    }
  
    try {
      const response = await axios.post(
        `${API_URL}api/preUser/uploads?institutionCode=${institutionCode}&role=${authUser.accountType}`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
  
      return response.data;
    } catch (error) {
      console.error("Error uploading file:", error.response || error);
      throw error.response?.data?.message || "An unexpected error occurred during file upload.";
    }
  };

export const deleteFiles = async (selectedFiles) => {
  try {
    const deleteRequests = selectedFiles.map((fileId) =>
      axios.delete(`${API_URL}api/preUser/files/${fileId}`)
    );

    await Promise.all(deleteRequests);  
    return "Files deleted successfully.";
  } catch (error) {
    console.error("Error deleting files:", error.response || error);
    throw error.response?.data?.message || "Failed to delete files.";
  }
};
