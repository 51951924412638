import React, { useEffect, useState } from "react";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import GeneralNavbar from "../navbar/GeneralNavbar";
import GeneralFooter from "../footer/GeneralFooter";
import axios from "axios";
import styles from "../../Styles"; // Import your styles
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import DialogBox from "../../Pages/newPages/dialog/DialogBox";


const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");
  const [passwordError, setPasswordError] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    await handleSave();
  }

  const openDialog = (message) => {
    setCurrentMessage(message);
    setIsDialogOpen(true);
  };

  const closeDialog = () =>{
    setIsDialogOpen(false);
    navigate("/home");
  }

  const authUser = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    console.log("Reached page!");
  
    const user = JSON.parse(window.sessionStorage.getItem('user'));
  
    if (user && user.user && user.otp) {
      setEmail(user.user.email);
      localStorage.setItem('savedEmail', user.user.email);  
      window.sessionStorage.clear();
      setIsLoggedIn(true);
    } else if (authUser && authUser.email) {
      setEmail(authUser.email);
      localStorage.setItem('savedEmail', authUser.email);  
      setIsLoggedIn(true);
    } else {
      
      const savedEmail = localStorage.getItem('savedEmail');
      if (savedEmail) {
        setEmail(savedEmail);  
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false);
        navigate("/ForgotPassword");
      }
    }
  }, [authUser]);

  const handleSave = async () => {

    if (newPassword.length < 8) {
      setErrorMessage("Password must be at least 8 characters long.");
      return;
    }

    if (/\s/.test(newPassword)) {
      setErrorMessage('Password cannot contain spaces.');
      return;
    }
    // Check if passwords match
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
      return;
    }
  

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + 'api/user/change-password', {
        newPassword: newPassword,
        email: email
      });
  
      console.log(response);
  
      if (response.data.success) {
        openDialog("Password Changed!");
      } else {
        setErrorMessage("An error has occurred. Please try again in a few minutes.");
      }
    } catch (error) {
      console.error("Error during password change:", error);
      setErrorMessage("Failed to update password. Please try again.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <GeneralNavbar />
      {/* Your other components and content */}
      <form onSubmit={handleSubmit} className="flex-grow flex justify-center items-center">
      <div className="flex-grow flex justify-center items-center">
        <div className="space-y-3 w-[400px] no-select md:p-0 p-2">
          <div>
            <p className="text-start font-bold text-2xl">Change Password</p>
            <p className="text-start font-light text-sm">
              Enter your new password
            </p>
          </div>
          <div className="flex flex-col">
            <label htmlFor="newPassword" className="font-bold cursor-pointer flex justify-between">
              <span>New Password</span>
            </label>
            <div className={`w-full ${styles.simple_text_input} border-4 flex justify-between items-center`}>
              <input
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                id="newPassword"
                placeholder="New Password"
                type={passwordVisibility ? "text" : "password"}
                className="focus:outline-none w-full"
                 autoComplete="new-password"
              />
              {passwordVisibility ? (
                <MdOutlineVisibility onClick={() => setPasswordVisibility(false)} className="mx-1 text-black cursor-pointer" />
              ) : (
                <MdOutlineVisibilityOff onClick={() => setPasswordVisibility(true)} className="mx-1 text-black cursor-pointer" />
              )}
            </div>
          </div>
          <div className="flex flex-col">
            <label htmlFor="confirmPassword" className="font-bold cursor-pointer">
              Confirm Password
            </label>
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              id="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              className={`${styles.simple_text_input} border-4`}
               autoComplete="new-password"
            />
          </div>
          <div>
          <p className="text-red-500">{errorMessage}</p>
          </div>
          <button type="submit" className="font-semibold text-white linearGradient_ver1 text-sm px-3 py-2 rounded-full w-full">
            Save
          </button>
        </div>
      </div>
      </form>
      <DialogBox
        isOpen={isDialogOpen}
        onClose={closeDialog}
        message={currentMessage}
      />
         <div className="mt-auto">
      <GeneralFooter />
    </div>
  
    </div>
   
  );
};

export default ChangePassword;
