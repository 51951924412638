import axios from 'axios';

const deletePreUsers = async (uploadId, selectedUsers) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/preUser/files/${uploadId}/delete-preUsers`,
        { emails: selectedUsers }
      );
  
      return response;  // Return success or error message
    } catch (error) {
      console.error("Error deleting users:", error);
      throw error;
    }
  };

const fetchPreUsers = async (uploadId, currentPage, itemsPerPage, filterStatus, searchTerm, sortDirection) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}api/preUser/files/${uploadId}/preUsers`,
      {
        params: {
          page: currentPage,
          limit: itemsPerPage,
          filterStatus,
          search: searchTerm,
          sortDirection,
        },
      }
    );

    return response.data;  // Contains preUsers and totalPages
  } catch (error) {
    console.error("Error fetching users:", error);
    throw error;  // Re-throw to handle in the component
  }
};

export { fetchPreUsers, deletePreUsers };
