import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const authUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const location = useLocation();
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  useEffect(() => {
    const checkAuthentication = () => {
      // Define allowed routes
      const isAllowedRoute =
        location.pathname === "/" ||
        location.pathname === "/home" ||
        location.pathname === "/registration" ||
        location.pathname === "/ForgotPassword" ||
        location.pathname === "/verifyotp" ||
        location.pathname === "/profile/profile-settings/changepassword" ||
        (location.pathname === "/registration" && location.search === "?screen=login");

      if (authUser === null && !isAllowedRoute) {
        navigate("/registration?screen=login");
      }
    };

    setIsCheckingAuth(true);
    checkAuthentication();
    setIsCheckingAuth(false);
  }, [authUser, navigate, location]);

  if (isCheckingAuth) {
    return <div>Loading...</div>;
  }

  return children;
};

export default AuthGuard;
