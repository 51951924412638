import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GeneralNavbar from "../../../../components/navbar/GeneralNavbar";
import BlobComposition from "../../../../components/backgrounds/BlobComposition/BlobComposition";
import { FaSortAlphaDownAlt } from "react-icons/fa";
import { IoMdSearch } from "react-icons/io";
import Pagination from "../../Profile/Pagination";
import { useLocation } from "react-router-dom";
import axios from "axios";
import DialogBox from "../../dialog/DialogBox";
import ConfirmationDialog from "../../dialog/ConfirmationDialog";
import SpinnerLoader from "../../../../components/loaders/SpinnerLoader";
import {deletePreUsers,fetchPreUsers} from "../../../../BackendProxy/uploadAPI"

  
  const UploadPage = () => {
    const location = useLocation();
    const { uploadId } = useParams();
    const [users, setUsers] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [filterStatus, setFilterStatus] = useState("All");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const [selectedUsers, setSelectedUsers] = useState([]);
    const { fileName } = location.state || {};
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentMessage, setCurrentMessage] = useState("");
    const [isConfirmDialogOpen, setConfirmIsDialogOpen] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [isInitial, setInitial] = useState(true);
    
  
    useEffect(() => {
      fetchUsers();
    }, [uploadId, currentPage, filterStatus, searchTerm, sortDirection]);
  
    const fetchUsers = async () => {
      try {
        if (isInitial) setInitialLoading(true);
    
        const fetchedUsers = await fetchPreUsers(uploadId,currentPage,itemsPerPage,filterStatus,searchTerm,sortDirection);
        setUsers(fetchedUsers.preUsers);
        setTotalPages(fetchedUsers.totalPages);
        setInitial(false);
        setInitialLoading(false); 
      
      } catch (error) {
        console.error("Error fetching users:", error);
        openDialog("Failed to fetch users.");
        setInitialLoading(false);
      }
    };
    
  
    const handleSort = () => {
      setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
      setCurrentPage(1);
    };
  
    const handleFilterStatusChange = (e) => {
      setFilterStatus(e.target.value);
      setCurrentPage(1);
    };
  
    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
      setCurrentPage(1);
    };
  
    const toggleUserSelection = (email) => {
      setSelectedUsers((prevSelected) =>
        prevSelected.includes(email)
          ? prevSelected.filter((selected) => selected !== email)
          : [...prevSelected, email]
      );
    };
  
    const deleteSelectedUsersAndEnrollments = async () => {
      try {
        const response = await deletePreUsers(uploadId,selectedUsers);
    
        if (response.status === 200) {
          openDialog(response.data.message);
    
          // Filter out deleted users locally
          const remainingUsers = users.filter(
            (user) => !selectedUsers.includes(user.email)
          );
          setUsers(remainingUsers);
          setSelectedUsers([]);
    
          // Adjust the page if no users remain
          if (remainingUsers.length === 0 && currentPage > 1) {
            // Delay setting the current page to prevent flickering
              setCurrentPage((prevPage) => prevPage - 1);
          } else {
            // Refresh data if still on the same page
            fetchUsers();
          }
    
          setConfirmIsDialogOpen(false);
        }
      } catch (error) {
        console.error("Error deleting users:", error);
        openDialog("Failed to delete users.");
      }
    };
  
    const openDialog = (message) => {
      setCurrentMessage(message);
      setIsDialogOpen(true);
    };

  return (
    <div className="relative h-full">
      <GeneralNavbar />
      <BlobComposition
        blobsData={[
          { top: "10%", left: "-20%", size: "700px" },
          { top: "-30%", left: "70%", size: "400px" },
          { top: "40%", left: "50%", size: "300px" },
        ]}
      />
 
      <div className="m-auto max-w-[1200px] mt-3 min-h-[100vh]">
        <div className="bg-white rounded-full flex justify-between items-center py-2 px-4">
        <div className="flex items-center space-x-3">
       <p> Uploaded File:</p>  <div className="text-blue-500">{fileName}</div>
        </div>
  

          <div className="flex items-center space-x-3">
          <button
      onClick={() => setConfirmIsDialogOpen(true)}
      disabled={selectedUsers.length === 0} // Disable if no users are selected
      className={`px-4 py-2 rounded-full font-semibold text-white ${
        selectedUsers.length > 0 ? "bg-red-500 hover:bg-red-600" : "bg-gray-300"
      }`}
    >
      Delete
    </button>
            <select
              value={filterStatus}
              onChange={handleFilterStatusChange}
              className="text-sm border px-2 py-1 rounded-full"
            >
              <option value="All">All</option>
              <option value="Pending">Pending</option>
              <option value="Accepted">Accepted</option>
            </select>
            <button
              onClick={handleSort}
              className="cursor-pointer hover:bg-stone-100 p-2 rounded-full transition-all"
              aria-label={`Sort ${sortDirection === "asc" ? "descending" : "ascending"}`}
            >
              <FaSortAlphaDownAlt
                className={`text-stone-800 transform ${
                  sortDirection === "desc" ? "rotate-180" : ""
                }`}
              />
            </button>
            <div className="flex items-center">
              <input
                placeholder="Search by email"
                value={searchTerm}
                onChange={handleSearchChange}
                className="text-sm focus:outline-none focus:border-b-stone-400 border-b-transparent border-b-[1.5px] pr-2 py-1 font-medium text-stone-600"
              />
              <IoMdSearch />
            </div>
          </div>
        </div>


        <div className="bg-white py-2 px-4 my-3 rounded-lg">
        
              <table className="table-auto w-full">
              <thead>
                <tr>
                  <th className="text-left w-[40px]">
                    {initialLoading || users.length === 0 ? (
                      <input type="checkbox" disabled className="opacity-0" />
                    ) : (
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            const newSelections = users.map((user) => user.email);
                            setSelectedUsers((prevSelected) => [
                              ...prevSelected,
                              ...newSelections.filter((email) => !prevSelected.includes(email)),
                            ]);
                          } else {
                            const deselected = users.map((user) => user.email);
                            setSelectedUsers((prevSelected) =>
                              prevSelected.filter((email) => !deselected.includes(email))
                            );
                          }
                        }}
                        checked={
                          users.length > 0 &&
                          users.every((user) => selectedUsers.includes(user.email))
                        }
                      />
                    )}
                  </th>
                  <th className="text-left">Email</th>
                  <th className="text-left w-1/4 pl-20">Sent on</th>
                  <th className="text-end w-1/4 pl-20">Status</th>
                </tr>
              </thead>
            
              <tbody className="text-sm">
                {initialLoading ? (
                <SpinnerLoader/>
                  )
                 : users.length > 0 ? (
                  // Actual data rows
                  users.map((user, index) => (
                    <tr
                      key={index}
                      className={`border-b ${
                        selectedUsers.includes(user.email) ? "bg-gray-200" : ""
                      }`}
                    >
                      <td className="py-2">
                        <input
                          type="checkbox"
                          checked={selectedUsers.includes(user.email)}
                          onChange={() => toggleUserSelection(user.email)}
                        />
                      </td>
                      <td className="py-2">{user.email}</td>
                      <td className="py-2 pl-20">
                        {new Date(user.sentOn).toLocaleString()}
                      </td>
                      <td className="py-2 pl-20">
                        <div className="flex justify-end">
                          <div
                            className={`px-2 py-1 text-sm flex items-center justify-center ${
                              user.status === "Accepted" ? "bg-green-500" : "bg-yellow-500"
                            } rounded-full`}
                          >
                            <p
                              className={`font-medium ${
                                user.status === "Accepted"
                                  ? "text-green-100"
                                  : "text-yellow-100"
                              }`}
                            >
                              {user.status}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4" className="py-4 text-center">
                      No content found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            

         
{!initialLoading && totalPages > 0 && (
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChange={(page) => setCurrentPage(page)}
    />
  )}
 
        
        </div>
        <DialogBox
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        message={currentMessage}
      />
      </div>
      <ConfirmationDialog
        isOpen={isConfirmDialogOpen}
        onClose={() => setConfirmIsDialogOpen(false)}
        onConfirm={deleteSelectedUsersAndEnrollments}
        message="Are you sure you want to delete this item(s)? This action cannot be undone."
      />
    </div>
  );
};

export default UploadPage;
