import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;


// Fetch Students by IDs
export const getStudentsByIds = async (studentIds) => {
  try {
    const response = await axios.post(
      `${API_URL}api/admin/get-students-by-ids`,
      {
        studentIds,
      }
    );
    return response.data.students;
  } catch (error) {
    console.error("Failed to fetch students by IDs:", error);
    throw error;
  }
};

// Send Email Notification
export const sendEmailNotification = async (title, message, recipients) => {
  try {
    const response = await axios.post(
      `${API_URL}api/admin/send-email-notification`,
      {
        title,
        message,
        recipients,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to send email notification:", error);
    throw error;
  }
};
