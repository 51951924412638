import React from 'react';

const CreateAccountSupport = () => {
    const steps = [
        {
            title: "Choose your account type",
            description: "Pick the account you want to create.",
            imageUrl: "/help-and-support-images/account-creation/ChooseAcc.png"
        },
        {
            title: "Enter Your Password",
            description: "Type your password in the password field. Make sure caps lock is off and your password matches the one you used during registration.",
            imageUrl: "/api/placeholder/400/300"
        },
        {
            title: "Two-Factor Authentication",
            description: "If you have 2FA enabled, you'll receive a code on your device. Enter this code in the verification field.",
            imageUrl: "/api/placeholder/400/300"
        }
    ];

    return (
        <div className="max-w-6xl mx-auto py-8">
            <h1 className="text-3xl font-bold mb-8">How to Sign In</h1>

            <div className="space-y-8">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
                    >
                        <div className="flex flex-col md:flex-row items-center">
                            {/* Text Content - Left side */}
                            <div className="flex-1 p-6 md:pr-8 flex flex-col justify-center order-2 md:order-1">
                                <h2 className="text-xl font-semibold mb-4">
                                    Step {index + 1}: {step.title}
                                </h2>
                                <p className="text-gray-600">
                                    {step.description}
                                </p>
                            </div>

                            {/* Image - Right side */}
                            <div className="md:w-1/2 h-64 md:h-72 bg-gray-100 order-1 md:order-2 p-2">
                                <div className="w-full h-full bg-white p-2 flex justify-center items-center">
                                    <img
                                        src={step.imageUrl}
                                        alt={`Step ${index + 1}: ${step.title}`}
                                        className="max-w-[90%] max-h-[90%] object-cover"
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default CreateAccountSupport;