import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";

const DialogBox = ({ isOpen, onClose, message }) => {
  
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        event.stopPropagation(); 
        onClose();
      }
    };
  
    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }
  
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <Dialog.Panel className="w-full max-w-md p-6 bg-white rounded shadow-lg">
        <Dialog.Description className="mt-2 text-center text-sm text-gray-700">
          {message}
        </Dialog.Description>
        <div className="mt-4 flex justify-center">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded hover:bg-blue-700"
          >
            Okay
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default DialogBox;
