import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL + 'api/admin';

const getTeachers = async ({ code, searchTerm = "", page = 1, limit = 5 }) => {
  try {
    const response = await axios.post(`${BASE_URL}/get-teachers`, {
      code,
      searchTerm,
      page,
      limit,
    });

    console.log(response.data.data); 
    return {
      data: response.data.data,
      totalPages: response.data.totalPages,
      currentPage: response.data.currentPage,
    };
  } catch (error) {
    console.error("Error fetching teachers:", error);
    throw error;
  }
};

const getTeachersByIds = async (teacherIds) => {
  try {
    const response = await axios.post(`${BASE_URL}/get-teachers-by-ids`, {
      teacherIds,
    });

   
    return response.data.teachers
  } catch (error) {
    console.error("Error fetching teachers:", error);
    throw error;
  }
};

 

export {getTeachers, getTeachersByIds};