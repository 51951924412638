import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GeneralNavbar from "../../../../components/navbar/GeneralNavbar";
import BlobComposition from "../../../../components/backgrounds/BlobComposition/BlobComposition";
import { IoMdSearch } from "react-icons/io";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import { FiDownload } from "react-icons/fi";
import { useSelector } from "react-redux";
import OnHoverExtraHud from "../../../../components/OnHoverExtraHud";
import getStudents from "../../../../BackendProxy/adminProxy/getStudents";
import getCoursesByProp from "../../../../BackendProxy/courseProxy/getCoursesByProp";
import getEnrolledCourses from "../../../../BackendProxy/courseProxy/getEnrolledCourses";
import * as XLSX from "xlsx";
import axios from "axios";
import { FiEye, FiEyeOff, FiBell} from "react-icons/fi";
import ProgressBar from "./ProgressBar";
import Pagination from "../../Profile/Pagination";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import DialogBox from "../../dialog/DialogBox";
import ConfirmationDialog from "../../dialog/ConfirmationDialog";
import {
  removeStudent,
  removeStudentsBulk,
  downloadStudentGrades,
  updateEnrollmentVisibilityMulti,
  updateSingleEnrollmentVisibility
} from "../../../../BackendProxy/studentAPI"




const AdminManageStudents = () => {
  const authUser = useSelector((state) => state.user);
  const [students, setStudents] = useState([]);
  const [courses, setCourses] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [selectedStudents, setSelectedStudents] = useState([]); 
  const [selectAll, setSelectAll] = useState(false);
  const [currentPage, setCurrentPage] = useState(1); 
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [confirmationAction, setConfirmationAction] = useState("");
const [totalPages, setTotalPages] = useState(1);
const [filteredTotalPages, setFilteredTotalPages] = useState(1);
  const itemsPerPage = 3; 
  const [filtersChanged, setFiltersChanged] = useState(false);
 // const totalPages = Math.ceil(filteredStudents.length / itemsPerPage);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
const [currentMessage, setCurrentMessage] = useState("");
const [isConfirmDialogOpen, setConfirmIsDialogOpen] = useState(false);

const openDialog = (message) => {
  setCurrentMessage(message);
  setIsDialogOpen(true);
};



  const navigate = useNavigate();

  const toggleSelectAll = () => {
    const allSelected = filteredStudents.every((student) =>
      selectedStudents.some((s) => s._id === student._id)
    );
  
    if (allSelected) {
      // Deselect all
      setSelectedStudents((prev) =>
        prev.filter((s) => !filteredStudents.some((f) => f._id === s._id))
      );
    } else {
      // Select all (avoid duplicates)
      const newSelections = filteredStudents.filter(
        (student) => !selectedStudents.some((s) => s._id === student._id)
      );
      setSelectedStudents((prev) => [...prev, ...newSelections]);
    }
  };
  
  useEffect(() => {
    const currentPageStudentIds = filteredStudents.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    ).map(student => student._id);

    const allSelected = currentPageStudentIds.every(id =>
      selectedStudents.includes(id)
    );
    console.log("total:" + totalCount);
    setSelectAll(allSelected);
  }, [selectedStudents, filteredStudents, currentPage, itemsPerPage]);

  /*
  useEffect(() => {
    const fetchStudentMetadata = async () => {
      try {
        const response = await axios.post(process.env.REACT_APP_API_URL + 'admin/get-students-meta', {
          code: authUser.institution.code,
        });
        setTotalCount(response.data.totalStudents); // Set total count
      } catch (error) {
        console.error("Error fetching student metadata:", error);
      }
    };
  
    fetchStudentMetadata();
   
  }, [authUser]);
*/

  
  // Fetch students and courses on load
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true); // Start loading
        await Promise.all([
       
          getAllCourses(authUser.institution.code),
        ]);
        setLoading(false); // End loading
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // End loading in case of error
      }
    };
    fetchAllData();
  }, [authUser]);

  useEffect(() => {
    console.log("Selected:",selectedStudents);
    getAllStudents(authUser.institution.code, currentPage);
  }, [authUser, currentPage]);
  
  
  useEffect(() => {
    setCurrentPage(1);  // Reset to page 1
    getAllStudents(authUser.institution.code, 1);  // Fetch page 1 data
  }, [searchTerm, selectedCourse]);
 /*

useEffect(() => {
  const lastPage = Math.ceil(filteredStudents.length / itemsPerPage);
  if (currentPage > lastPage && lastPage > 0) {
    // Instead of forcing reset immediately, only go back if the page is empty
    if (filteredStudents.length === 0) {
      setCurrentPage(1);
    } else {
      setCurrentPage(lastPage);
    }
  }
}, [filteredStudents, itemsPerPage]);

*/


  const downloadGradesAsZip = async () => {
    try {

      const response = await axios.post(process.env.REACT_APP_API_URL + 'api/course/download-zip-students-grades', {
        studentIds: filteredStudents, 

      }, {
        responseType: 'blob', // This ensures you handle the zip file as binary data
      });
      
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'students_grades.zip'); // File name for download
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error downloading the zip file:', error);
    }
  };

  const removeStudentFromCourse = async (studentId, studentName) => {
    try {
      const isAllCourses = selectedCourse === "";
  const response = await removeStudent(studentId, isAllCourses
    ? null
    : courses.find((course) => course.title === selectedCourse)?._id,isAllCourses );
     
      if (response.success) {
        openDialog(`${studentName} removed from ${
          isAllCourses ? "all courses" : selectedCourse
        }.`);
  
        // Fetch updated list for the current page
        const updatedRes = await getStudents(
          authUser.institution.code,
          currentPage,
          itemsPerPage,
          searchTerm,
          selectedCourse
        );
  
        // Calculate the new page if no students remain
        const newPage = updatedRes.data.length === 0 && currentPage > 1
          ? currentPage - 1  // Go back a page if empty
          : currentPage;     // Stay on the same page if students remain
  
        console.log("Updated Total (All Courses):", updatedRes.total);
        console.log("New Page (After Deletion):", newPage);
  
        setCurrentPage(newPage);
        await getAllStudents(authUser.institution.code, newPage);
        setSelectedStudents([]);
      } else {
        openDialog(`Failed to remove ${studentName} from the course(s).`);
      }
    } catch (error) {
      console.error("Error removing student from course:", error);
      openDialog("An error occurred. Please try again.");
    }
  };
  const removeFromCourseMulti = async () => {
    try {
      const isAllCourses = selectedCourse === "";
  
      if (selectedStudents.length === 0) {
        openDialog("No students selected to remove.");
        return;
      }
  
      const studentIds = selectedStudents.map((student) => student._id);
      const selectedCourseId = isAllCourses
        ? null  // Null courseId when removing from all courses
        : courses.find((course) => course.title === selectedCourse)?._id;
  
      if (!isAllCourses && !selectedCourseId) {
        openDialog("Selected course not found.");
        return;
      }
  
      const removalResponse = await removeStudentsBulk(studentIds,selectedCourseId,isAllCourses);
    
      if (removalResponse.success) {
        openDialog(`${studentIds.length} student(s) removed successfully.`);
  
        // Fetch updated student list for the current page
        const updatedRes = await getStudents(
          authUser.institution.code,
          currentPage,
          itemsPerPage,
          searchTerm,
          selectedCourse
        );
  
        // If no students remain on the current page, move to the previous page
        const newPage = updatedRes.data.length === 0 && currentPage > 1
          ? currentPage - 1
          : currentPage;
  
        console.log("Updated Total (All Courses):", updatedRes.total);
        console.log("New Page (After Deletion):", newPage);
  
        setCurrentPage(newPage);
        await getAllStudents(authUser.institution.code, newPage);
  
        setSelectedStudents([]);
      } else {
        openDialog("Failed to remove students from the course(s).");
      }
    } catch (error) {
      console.error("Error removing students from course:", error);
      openDialog("An error occurred. Please try again.");
    }
  };


  const removeStudentFromInstitution = async (studentId) => {
    try {

     
     setConfirmIsDialogOpen(true);
     
      const response = await axios.post(
        process.env.REACT_APP_API_URL + 'api/user/update-only-institution-code',
        {
          _id: studentId,
          code: "NO-INSTITUTION", 
        }
      );
  
      if (response.data.success) {
        openDialog("Student removed from the institution.");
    
        setStudents((prev) => prev.filter((student) => student._id !== studentId));
      } else {
        openDialog("Failed to remove student.");
      }
    } catch (error) {
      console.error("Error removing student:", error);
    }
  };

  const removeSelectedStudents = async () => {
    try {
      // Determine the target students: selected or all students
      const studentsToRemove =
        selectedStudents.length > 0
          ? students.filter((student) => selectedStudents.includes(student._id))
          : students;
  
      if (studentsToRemove.length === 0) {
        openDialog("No students to remove.");
        return;
      }
  
      // Confirm removal action
      if (
        !window.confirm(
          `Are you sure you want to remove ${studentsToRemove.length} student(s)?`
        )
      ) {
        return;
      }
  
      // Loop through and remove each student
      for (const student of studentsToRemove) {
        await axios.post(process.env.REACT_APP_API_URL + 'api/user/update-only-institution-code', {
          _id: student._id,
          code: "NO-INSTITUTION", // Mark as removed from institution
        });
  
       
        setStudents((prev) =>
          prev.filter((s) => s._id !== student._id)
        );
      }
      openDialog(`${studentsToRemove.length} student(s) removed successfully.`);
      setSelectedStudents([]); 
    } catch (error) {
      console.error("Error removing students:", error);
      openDialog("Failed to remove students.");
    }
  };

  const getAllStudents = async (code, page) => {
    try {
      const res = await getStudents(code, page, itemsPerPage, searchTerm, selectedCourse);
      setStudents(res.data);
      setFilteredStudents(res.data);
      setTotalPages(res.totalPages);
      setTotalCount(res.total);  // Set total count for pagination
      console.log(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  
  const toggleMultiVisibility = async () => {
    if (!selectedCourse) {
      openDialog("Please select a course to toggle visibility.");
      return;
    }
  
    try {
      const selectedStudentIds = selectedStudents.map((student) => student._id);
  
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}api/course/get-enrollments`,
        {
          studentIds: selectedStudentIds,
          courseTitle: selectedCourse,
        }
      );
  
      const enrollments = response.data.enrollments;
  
      if (enrollments.length === 0) {
        openDialog("No matching enrollments found.");
        return;
      }
  
      // Toggle visibility in the backend
      const updates = enrollments.map((enrollment) => ({
        enrollmentId: enrollment._id,
        visible: !enrollment.visible,
      }));
  
      const updateResponse = await updateEnrollmentVisibilityMulti(updates);

      if (updateResponse.success) {
        openDialog("Visibility updated successfully.");
  
        // Update state immutably
        const updatedStudents = students.map((student) => {
          // Check if the student is selected
          if (selectedStudentIds.includes(student._id)) {
            // Create a new student object with updated enrollments
            return {
              ...student,
              enrollments: student.enrollments.map((enrollment) =>
                enrollment.course.title === selectedCourse
                  ? { ...enrollment, visible: !enrollment.visible }  // Toggle visibility safely
                  : enrollment
              ),
            };
          }
          return student;  // Return unmodified for others
        });
  
        setStudents(updatedStudents);
        setFilteredStudents(updatedStudents);  // Update filtered list as well
        setSelectedStudents([]);  // Clear selections
      } else {
        openDialog("Failed to update visibility.");
      }
    } catch (error) {
      console.error("Error toggling visibility:", error);
      openDialog("An error occurred while updating visibility.");
    }
  };
  
  

  const toggleVisibility = async (enrollmentId, currentVisibility) => {
    try {
      const response = await updateSingleEnrollmentVisibility(enrollmentId,!currentVisibility);
console.log(response);
      if (response.success) {
        openDialog("Visibility updated successfully.");
        // Re-fetch students to ensure the UI reflects the change
        getAllStudents(authUser.institution.code, currentPage);
      } else {
        openDialog("Failed to update visibility.");
      }
    } catch (error) {
      console.error("Error toggling visibility:", error);
      openDialog("An error occurred. Please try again.");
    }
  };
  const getAllCourses = async (code) => {
    try {
      const res = await getCoursesByProp("creator.email", authUser.email, code);
      setCourses(res.res);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCheckboxChange = (student) => {
    setSelectedStudents((prev) => {
      const isAlreadySelected = prev.some((s) => s._id === student._id);
      
      if (isAlreadySelected) {
        return prev.filter((s) => s._id !== student._id);  // Deselect
      } else {
        return [...prev, student];  // Add student object
      }
    });
  };

  const isChecked = (studentId) =>
    selectedStudents.some((student) => student._id === studentId);


  const downloadGradesOneFile = async () => {
    try {
      if (selectedStudents.length === 0) {
        openDialog("No students selected to download grades.");
        return;
      }

      const selectedStudentIds = selectedStudents.map((student) => student._id);
  
      // Fetch grades for all selected students
      const response = await downloadStudentGrades(selectedStudentIds,selectedCourse || null);
      const gradesData = response.data.data;
  
      if (gradesData.length === 0) {
        openDialog("No grades available for the selected students or courses.");
        return;
      }
  
      const studentsData = selectedStudents;
      console.log(studentsData);
      const studentMap = {};
      studentsData.forEach((student) => {
        studentMap[student._id] = student;
      });

      // Generate and format the Excel report
      generateExcelReport(gradesData, studentMap);
  
    } catch (error) {
      console.error("Error downloading students' grades:", error);
      openDialog("Failed to download grades.");
    }
  };
  
  // Generate Excel Report with Student Info
  const generateExcelReport = (gradesData, studentMap) => {
    let allGrades = [];
  
    // Group grades by student and course for proper ordering
    const groupedGrades = gradesData.reduce((acc, item) => {
      const key = `${item.studentId}-${item.course}`;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});
  
    // Process each group (student-course pair)
    Object.entries(groupedGrades).forEach(([key, grades]) => {
      // Sort to ensure individual grades come before cumulative
      grades.sort((a, b) => (a.type === 'Individual' ? -1 : 1));
  
      grades.forEach((item) => {
        const student = studentMap[item.studentId] || {};  // Retrieve student details
  
        allGrades.push({
          Email: student.email || "N/A",
          Username: student.username || "N/A",
          'First Name': student.firstName || "N/A",
          'Last Name': student.lastName || "N/A",
          Course: item.course,
          'Lesson Title': item.type === 'Cumulative' ? "" : item.lessonTitle || "N/A",
          Grade: Number(item.grade).toFixed(2),
          'Type of Grade': item.type,
        });
      });
    });
  
    // Create worksheet and workbook
    const worksheet = XLSX.utils.json_to_sheet(allGrades);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Students Grades");
  
    // Auto-adjust column widths based on content and headers
    const calculateMaxWidth = (data, header) => {
      const contentWidths = data.map((item) =>
        item ? item.toString().length : 0
      );
      const maxContentWidth = Math.max(...contentWidths, 0);
      return Math.max(maxContentWidth, header.length) + 3;
    };
  
    worksheet["!cols"] = [
      { wch: calculateMaxWidth(allGrades.map((row) => row.Email), "Email") },
      { wch: calculateMaxWidth(allGrades.map((row) => row.Username), "Username") },
      { wch: calculateMaxWidth(allGrades.map((row) => row["First Name"]), "First Name") },
      { wch: calculateMaxWidth(allGrades.map((row) => row["Last Name"]), "Last Name") },
      { wch: calculateMaxWidth(allGrades.map((row) => row.Course), "Course") },
      { wch: calculateMaxWidth(allGrades.map((row) => row["Lesson Title"]), "Lesson Title") },
      { wch: calculateMaxWidth(allGrades.map((row) => row.Grade), "Grade") },
      { wch: calculateMaxWidth(allGrades.map((row) => row["Type of Grade"]), "Type of Grade") },
    ];
  
    // Add header row manually for custom formatting
    XLSX.utils.sheet_add_aoa(worksheet, [
      ["Email", "Username", "First Name", "Last Name", "Course", "Lesson Title", "Grade", "Type of Grade"]
    ], { origin: "A1" });
  
    // Download the file with the appropriate name
    const filename = selectedCourse
      ? `${selectedCourse}_Grades.xlsx`
      : `All_Students_Grades.xlsx`;
    XLSX.writeFile(workbook, filename);

    setSelectedStudents([]);
  };
  

  const handleSendBulkNotification = () => {
    if (selectedStudents.length === 0) {
      openDialog("No students selected for notification.");
      return;
    }
    navigate(`/admin/send-notification`, { state: { studentIds: selectedStudents, sender: authUser.username  } });
  };

  const currentStudents = filteredStudents;

  // Page change handler
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
          <DialogBox
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        message={currentMessage}
      />
       <ConfirmationDialog
  isOpen={isConfirmDialogOpen}
  onClose={() => setConfirmIsDialogOpen(false)}
  onConfirm={async () => {
    if (confirmationAction === "multiCourseRemove") {
      await removeFromCourseMulti(); 
    } else {
      console.warn("No valid action specified.");
    }
  }}
        message="Are you sure you want to delete this item(s)? This action cannot be undone."
      />
      <GeneralNavbar />
      <BlobComposition
        blobsData={[
          { top: "10%", left: "-20%", size: "700px" },
          { top: "-30%", left: "70%", size: "400px" },
          { top: "40%", left: "50%", size: "300px" },
        ]}
      />
      <div className="m-auto max-w-[1200px] mt-3 min-h-[100vh]">
        <div className="bg-white rounded-full flex justify-between items-center py-2 px-4">
          <p className="font-semibold text-lg">Students List</p>
          <div className="flex items-center space-x-3">
       

          <div className="relative" style={{ width: "200px" }}>
  <select
    value={selectedCourse}
    onChange={(e) => setSelectedCourse(e.target.value)}
    className="border px-3 py-1 rounded-md text-sm truncate w-full"
  
  >
    <option value="">All Courses</option>
    {courses.map((course) => (
      <option key={course._id} value={course.title}>
        {course.title.length > 30 ? `${course.title.slice(0, 30)}...` : course.title }
      </option>
    ))}
  </select>
</div>
            <div className="flex items-center">
              <input
                placeholder="Search by name"
                className="text-sm focus:outline-none focus:border-b-stone-400 border-b-transparent border-b-[1.5px] pr-2 py-1 font-medium text-stone-600"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <IoMdSearch />
            </div>
          </div>
        </div>
    

        <div className="my-2 flex items-center justify-end">

          <button
            onClick={() => navigate("/admin/invite/students")}
            className="text-white font-medium px-3 py-1 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
          >
            + Invite students
          </button>

          <button
  onClick={handleSendBulkNotification}
  className="text-white font-medium px-3 py-1 ml-3 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
>
  Send Notification
</button>

<button
    onClick={() => {
      if (selectedStudents.length === 0) {
        openDialog("Please select at least one student to download grades.");
      } else {
        downloadGradesOneFile();
      }
    }}
    className="text-white font-medium px-3 py-1 ml-3 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
  >
    Download Grades
  </button>
     

  <button
  onClick={() => {
    if (selectedStudents.length === 0) {
      openDialog("Please select at least one student to toggle visibility.");
    } else {
      toggleMultiVisibility();
    }
  }}
  className="text-white font-medium px-3 py-1 ml-3 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
>
  Toggle Visibility 
</button>
  <button
    onClick={() => {
      if (selectedStudents.length === 0) {
        openDialog("Please select at least one student to remove.");
      } else {
       setConfirmationAction("multiCourseRemove");
       setConfirmIsDialogOpen(true);
      }
    }}
    className="text-white font-medium px-3 py-1 ml-3 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
  >
    Remove
  </button>
  </div>

  <div className="bg-white py-2 px-4 mt-1 rounded-lg">
  <table className="table-auto w-full">
    <thead>
      <tr>
        <th className="w-1/4">
          <div className="flex items-center space-x-2">
            <input
              type="checkbox"
              className="w-5 h-5"
              style={{
                visibility: loading ? "hidden" : "visible",
              }}
              disabled={loading} // Prevent interaction during loading
              checked={
                !loading &&
                filteredStudents.length > 0 &&
                filteredStudents.every((student) =>
                  selectedStudents.some((s) => s._id === student._id)
                )
              }
              onChange={!loading ? toggleSelectAll : undefined}
            />
            <label className="font-bold">Username</label>
          </div>
        </th>
        <th className="w-1/4">Email</th>
        <th className="w-1/4">Progress</th>
        <th className="w-1/4 text-end">Options</th>
      </tr>
    </thead>
    <tbody>
  {loading ? (
    <tr>
      <td colSpan="4" className="text-center py-3">
        <AiOutlineLoading3Quarters className="animate-spin text-xl text-gray-500" />
      </td>
    </tr>
  ) : filteredStudents.length > 0 ? (
    currentStudents.map((student) => (
      <StudentCard
        key={student._id}
        student={student}
        selectedCourse={selectedCourse}
        toggleVisibility={toggleVisibility}
        isChecked={isChecked}
        handleCheckboxChange={handleCheckboxChange}
        removeStudentFromInstitution={removeStudentFromInstitution}
        removeStudentFromCourse={removeStudentFromCourse}
        authUser={authUser}
        openDialog={openDialog}
        confirmationAction={confirmationAction}
      />
    ))
  ) : (
    <tr>
      <td colSpan="4" className="text-center py-4 text-gray-500">
        No students available.
      </td>
    </tr>
  )}
</tbody>
  </table>
  {!loading && filteredStudents.length > 0 && (
    <Pagination
      totalPages={totalPages}
      currentPage={currentPage}
      onPageChange={handlePageChange}
    />
  )}
</div>
      </div>
    </div>
  );
};

const StudentCard = ({ student, selectedCourse, toggleVisibility, isChecked,handleCheckboxChange, removeStudentFromCourse, authUser,openDialog,confirmationAction }) => {
  // Find the enrollment matching the selected course (if any)
  const navigate = useNavigate();
  const selectedEnrollment = student.enrollments?.find(
    (enrollment) => enrollment.course?.title === selectedCourse
  );

  const [isConfirmDialogOpen, setConfirmIsDialogOpen] = useState(false);


  const calculateProgress = (enrollment) => {
    if (enrollment && enrollment.course) {
      const nonTextLessons = enrollment.course.lessons.filter(
        (lesson) => lesson.lessonContent && lesson.lessonContent.type !== 'text'
      );
      const totalLessons = nonTextLessons.length;
  
      const completedNonTextLessons = enrollment.completedLessons.filter(
        (lessonId) => nonTextLessons.some((lesson) => lesson._id.toString() === lessonId.toString())
      ).length;
  
      return totalLessons > 0
        ? (completedNonTextLessons / totalLessons) * 100
        : 0;
    }
    return 0;
  };
  // Calculate average progress for all enrollments
  const calculateAverageProgress = () => {
    if (!student.enrollments || student.enrollments.length === 0) return 0;
    const totalProgress = student.enrollments.reduce(
      (sum, enrollment) => sum + calculateProgress(enrollment),
      0
    );
    return totalProgress / student.enrollments.length;
  };

  // Determine the progress to display
  const progress = selectedCourse
    ? calculateProgress(
        student.enrollments.find(
          (enrollment) => enrollment.course.title === selectedCourse
        )
      )
    : 0; // Use average if "All Courses" is selected
  // Condition to show visibility only if a specific course is selected
  const showVisibilityIcon = selectedCourse && selectedEnrollment;

  const { _id: enrollmentId, visible } = selectedEnrollment || {};

  /*
  const progress = selectedEnrollment
  ? calculateProgress(selectedEnrollment)
  : 0; // Default to 0 if no enrollment found
 */
  const downloadGrades = async (studentId, studentName) => {
    try {
      const response = await downloadStudentGrades([studentId],selectedCourse || null);
  
      const gradesData = response.data.data;
  
      if (gradesData.length === 0) {
        openDialog("No grades available for this student.");
        return;
      }
  
      let worksheetData = [];

      // Group grades by course title
      const gradesByCourse = gradesData.reduce((acc, grade) => {
        acc[grade.course] = acc[grade.course] || [];
        acc[grade.course].push(grade);
        return acc;
      }, {});

      // Process each course and append individual + cumulative grades
      Object.entries(gradesByCourse).forEach(([course, courseGrades]) => {
        let totalGrades = 0;

        const processedLessons = new Set();  // Track processed lessons to avoid duplication

        // Add individual grades for the course
        courseGrades.forEach((grade) => {
          // Avoid duplicate cumulative entry by checking if lesson is processed
          if (!processedLessons.has(grade.lessonTitle) && grade.type === 'Individual' ) {
            worksheetData.push({
              'Course': grade.course,
              'Lesson Title': grade.lessonTitle,
              'Grade': Number(grade.grade).toFixed(2),
              'Type of Grade': 'Individual',
            });
            totalGrades += Number(grade.grade);
            processedLessons.add(grade.lessonTitle);  // Mark as processed
          }
        });

        // Calculate and add cumulative grade for the course (if not already)
        if (courseGrades.some(grade => grade.type === 'Cumulative')) {
          const cumulativeGrade = (totalGrades / processedLessons.size).toFixed(2);
          worksheetData.push({
            'Course': course,
            'Lesson Title': '',
            'Grade': cumulativeGrade,
            'Type of Grade': 'Cumulative',
          });
        }
      });
  
      // Create the worksheet
      const worksheet = XLSX.utils.json_to_sheet(worksheetData);
  
      // Auto-adjust column widths
      const maxWidth = (data) =>
        Math.max(...data.map((item) => (item ? item.toString().length : 0))) + 3;
  
      worksheet['!cols'] = [
        { wch: maxWidth(worksheetData.map((row) => row.Course)) },
        { wch: maxWidth(worksheetData.map((row) => row['Lesson Title'])) },
        { wch: maxWidth(worksheetData.map((row) => row.Grade)) },
        { wch: maxWidth(worksheetData.map((row) => row['Type of Grade'])) },
      ];
  
      // Create and download the Excel file
      const filename = selectedCourse
        ? `${studentName}_${selectedCourse}_Grades.xlsx`
        : `${studentName}_All_Courses_Grades.xlsx`;
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Grades');
      XLSX.writeFile(workbook, filename);
      
    } catch (error) {
      console.error('Error downloading grades:', error);
      openDialog("Failed to download grades.");
    }
  };


  const handleSendNotification = (studentId) => {
    navigate(`/admin/send-notification`, { state: { studentIds: [studentId], sender:authUser.username } });
  };

  const rowStyle = isChecked(student._id)
    ? { backgroundColor: '#b2d8d8' } 
    : { backgroundColor: 'white' };  

  return (
    <tr style={rowStyle}>
      <td className="flex items-center space-x-2">
      <ConfirmationDialog
  isOpen={isConfirmDialogOpen}
  onClose={() => setConfirmIsDialogOpen(false)}
  onConfirm={() => removeStudentFromCourse(student._id, student.username)}
        message="Are you sure you want to delete this item(s)? This action cannot be undone."
      />

        <input
          type="checkbox"
          checked={isChecked(student._id)}
          onChange={() => handleCheckboxChange(student)}
          className="w-5 h-5"
        />
        <span>{student.username || "Unknown"}</span>
      </td>
      <td>{student.email || "No Email"}</td>
      <td className="w-[30%]">
        <div className="flex items-center space-x-2">
          <span className="text-sm text-gray-600 w-12 text-right">
            {progress.toFixed(1)}%
          </span>
          <div className="flex-grow">
            <ProgressBar progress={progress} />
          </div>
        </div>
      </td>

      <td className="flex space-x-6 items-center justify-end">
        {showVisibilityIcon && (
          <button
            className="p-2 hover:bg-green-200 transition-all bg-green-100 rounded-full cursor-pointer flex items-center justify-center relative focus:outline-none hover-parent"
            onClick={() => toggleVisibility(enrollmentId, visible)}
            aria-label={visible ? "Hide" : "Show"}
          >
            {visible ? (
              <FiEye className="text-green-600" />
            ) : (
              <FiEyeOff className="text-gray-600" />
            )}
            <OnHoverExtraHud name={visible ? "Visible" : "Hidden"} />
          </button>
        )}
        <button
          className="p-2 rounded-full transition-all bg-yellow-100 hover:bg-yellow-200 cursor-pointer flex items-center justify-center relative focus:outline-none hover-parent"
          onClick={() => handleSendNotification(student._id)}
          aria-label="Send Notification"
        >
          <FiBell className="text-yellow-600" />
          <OnHoverExtraHud name="Send Notification" />
        </button>
        <button
          className="p-2 hover:bg-blue-200 transition-all bg-blue-100 rounded-full cursor-pointer flex items-center justify-center relative focus:outline-none hover-parent"
          onClick={() => setConfirmIsDialogOpen(true)}
          aria-label="Delete"
        >
          <RiDeleteBin7Fill className="text-md text-blue-700" />
          <OnHoverExtraHud name="Remove" />
        </button>
        <button
          className="p-2 rounded-full transition-all bg-green-100 hover:bg-green-200 cursor-pointer flex items-center justify-center relative focus:outline-none hover-parent"
          onClick={() => downloadGrades(student._id, student.username)}
          aria-label="Download Grades"
        >
          <FiDownload className="text-md text-green-600" />
          <OnHoverExtraHud name="Download Grades" />
        </button>
      </td>
    </tr>
  );
};

export default AdminManageStudents;

