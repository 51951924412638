import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import BlobComposition from "../../../components/backgrounds/BlobComposition/BlobComposition";
import { MdContentCopy, MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";
import OnHoverExtraHud from "../../../components/OnHoverExtraHud";
import checkInvitationCode from "../../../BackendProxy/userProxy/checkInvitationCode";
import { IoMdSend } from "react-icons/io";
import SpinnerLoader from "../../../components/loaders/SpinnerLoader";
import { useNavigate } from "react-router-dom";
import { FaGoogle } from "react-icons/fa";
import styles from "../../../Styles";
import { CgDanger } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { setUser } from "../../../redux/slice/user/userSlice";
import saveUserOnCookies from "../../../BackendProxy/cookiesProxy/saveUserCookies";
import DialogBox from "../dialog/DialogBox";
import { create } from "@mui/material/styles/createTransitions";


const CreateAccAdmin = () => {
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("phase");
 


  const [validCode, setValidCode] = useState(false);

  useEffect(() => {
    if (!id) {
      navigate("/registration?screen=admin&phase=1");
    }
  }, [id]);

  return (
    <div className="relative min-h-[80vh]">
      <BlobComposition
        blobsData={[
          { top: "10%", left: "-20%", size: "700px" },
          { top: "-30%", left: "70%", size: "700px" },
        ]}
      />
      {id == 1 && <PhaseOne setValidCode={setValidCode} />}
      {id == 2 && <PhaseTwo />}
    </div>
  );
};

const PhaseOne = ({ setValidCode }) => {
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();  
    sendInvitatonCode(code);
    };

  const openDialog = (message) => {
    setCurrentMessage(message);
    setIsDialogOpen(true);
  };

  const sendInvitatonCode = async (code) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const checkedCode = await checkInvitationCode(code);
      console.log(checkedCode);
      if (checkedCode.success) {
        setValidCode(true);
        navigate("/registration?screen=admin&phase=2&code=" + code);
      } else {
        setNotFound(true); // Code is invalid but request succeeded
      }
    } catch (err) {
      setNotFound(true); // Handle failed requests
      console.error("Error checking invitation code:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setCode(e.target.value);
  };

  const handlePasteButtonClick = () => {
    navigator.clipboard.readText()
      .then((text) => {
        setCode(text);
        inputRef.current.focus();
      })
      .catch((err) => {
        console.error("Error reading clipboard:", err);
        openDialog("Could not read clipboard.");
      });
  };

  return (
    <form onSubmit={handleSubmit} >
    <div className="m-auto max-w-[1200px] h-[80vh] flex flex-col justify-between">
      <div className="flex flex-col items-center justify-center">
 
        <div className="bg-white rounded-full w-full py-2 px-[3rem] flex items-center justify-center">
          <p className="font-medium text-2xl">Enter your invitation code here</p>
        </div>
        <div className="mt-4 max-w-[500px] bg-white px-3 py-2 rounded-full flex items-center space-x-2 transition-all border border-gray-300">
          <div
            className="p-2 rounded-full hover:bg-stone-100 cursor-pointer hover-parent"
            onClick={handlePasteButtonClick}
          >
            <OnHoverExtraHud name="paste" />
            <MdContentCopy className="text-stone-500" />
          </div>
          <input
            ref={inputRef}
            value={code}
            onChange={(e) => handleInputChange(e)}
            placeholder="#000000"
            className="w-[80%] focus:outline-none text-center "
            autoComplete="off"
          />
        </div>
        {notFound && (
          <p className="text-red-500 text-sm mt-2">The institution code you entered is incorrect. Please try again.</p>
        )}
        <button
        type = "submit"
          className="font-semibold rounded-full hover:bg-stone-100 cursor-pointer mt-3 flex items-center space-x-2 text-white px-3 py-1 transition duration-300 transform hover:-translate-y-1 linearGradient_ver1"
        >
          {loading ? <SpinnerLoader /> : "SUBMIT"}
        </button>

      </div>
    
      <div className="flex items-center justify-center flex-col text-sm">
        <p className="font-medium cursor-pointer">FAQ</p>
        <p className="cursor-pointer hover:underline">Where can I get my invitation code?</p>
        <p className="cursor-pointer hover:underline">How to request my invitation code?</p>
        <p className="cursor-pointer hover:underline">What's the Invitation Code?</p>
        
      </div>

      <DialogBox
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        message={currentMessage}
      />
    </div>
    </form>
  );
};


const PhaseTwo = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [missingData, setMissingData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordVisibility, setPasswordVisibility] = useState(false);
  const [confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);
  

  const handleSubmit = (event) => {
    event.preventDefault();  
    createAccount();
    };


  const createAccount = async () => {
    setLoading(true);
    resetErrors();

    if (!validateFormData()) {
      // Display the general "fill required fields" message
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + 'api/user/create-user', {
        email,
        accountType: 'admin',
        username,
        password,
        code: code,
      });

      if (response.data.success) {
        const savedUser = await saveUserOnCookies({ ...response.data.user });
        await dispatch(setUser(savedUser));
        navigate('/');
      } else {
        // Handle server error messages
        if (response.data.message === 'The email is already in use') {
          setEmailError('This email is already in use. Please use a different email.');
        } else if (response.data.message === 'The username is already taken') {
          setUsernameError('This username is already taken. Please choose another.');
        } else {
          setEmailError('An unexpected error occurred. Please try again later.');
        }
      }
    } catch (error) {
      console.error('Error:', error);
      setEmailError('A server error occurred. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const validateFormData = () => {
    let isValid = true;

    setMissingData(false);
    setEmailError('');
    setPasswordError('');
    setConfirmPasswordError('');


    if (!email || !username || !password || !confirmPassword) {
      setMissingData(true);
      return false; 
  } 

    if (!/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/.test(email)) {
      setEmailError('Invalid email format.');
      isValid = false;
    } else if (!/^[^\s@]+\.dev@projecthumancity\.com$/.test(email)) {
      setEmailError('Email must be from the @projecthumancity.com domain.');
      isValid = false;
    }

    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long.');
      isValid = false;
    }

    if (/\s/.test(password)) {
      setPasswordError('Password cannot contain spaces.');
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match.');
      isValid = false;
    }

    return isValid;
  };

  const resetErrors = () => {
    setEmailError('');
    setUsernameError('');
    setPasswordError('');
    setConfirmPasswordError('');
    setMissingData(false);
  };

  return (
    <form onSubmit={handleSubmit} >
    <div className="space-y-3 w-[400px] no-select md:p-0 p-2 ">
      <div>
        <p className="text-center font-bold text-xl">Create Admin Account</p>
      </div>

      {missingData && (
        <div className="flex items-center justify-between text-red-400 mb-3">
          <p>Please fill in all required fields.</p>
          <CgDanger className="ml-1" />
        </div>
      )}

      <div className="flex flex-col ">
        <label htmlFor="email" className="font-bold pl-2 pt-2">
          Email
        </label>
        <input
          id="email"
          placeholder="Email"
          type="text"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className={`${styles.simple_text_input}`}
          autoComplete="off"
        />
        {emailError && <p className="text-red-500 text-sm">{emailError}</p>}

        <label htmlFor="username" className="font-bold pl-2 pt-2">
          Academy Name
        </label>
        <input
          id="username"
          placeholder="Academy name"
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          className={`${styles.simple_text_input}`}
          autoComplete="off"
        />
        {usernameError && <p className="text-red-500 text-sm">{usernameError}</p>}

        <label htmlFor="password" className="font-bold pl-2 pt-2">
          Password
        </label>
        <div className={`w-full ${styles.simple_text_input} flex justify-between items-center bg-white`}>
          <input
            id="password"
            placeholder="Password"
            value={password}
            type={passwordVisibility ? 'text' : 'password'}
            onChange={(e) => setPassword(e.target.value)}
            className="focus:outline-none w-full"
            autoComplete="new-password"
          />
          {passwordVisibility ? (
            <MdOutlineVisibility onClick={() => setPasswordVisibility(false)} className="mx-1 text-black cursor-pointer" />
          ) : (
            <MdOutlineVisibilityOff onClick={() => setPasswordVisibility(true)} className="mx-1 text-black cursor-pointer" />
          )}
        </div>
        {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}

        <label htmlFor="confirm-password" className="font-bold pl-2 pt-3">
          Confirm Password
        </label>
        <div className={`w-full ${styles.simple_text_input} flex justify-between items-center bg-white`}>
          <input
            id="confirm-password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            type={confirmPasswordVisibility ? 'text' : 'password'}
            className="focus:outline-none w-full"
            autoComplete="new-password"
          />
          {confirmPasswordVisibility ? (
            <MdOutlineVisibility onClick={() => setConfirmPasswordVisibility(false)} className="mx-1 text-black cursor-pointer" />
          ) : (
            <MdOutlineVisibilityOff onClick={() => setConfirmPasswordVisibility(true)} className="mx-1 text-black cursor-pointer" />
          )}
        </div>
        {confirmPasswordError && <p className="text-red-500 text-sm">{confirmPasswordError}</p>}
      </div>

      <button type="submit" className="font-semibold text-white linearGradient_ver1 text-sm px-3 py-2 rounded-full w-full">
        {loading ? <SpinnerLoader /> : 'Create account'}
      </button>
    </div>
    </form>
  );
};

export default CreateAccAdmin;
