import React from 'react';
// import {cc1, cc2, cc3, cc4, cc5, cc6, cc7, cc8, cc9} from "../Images/help-and-support-images/Course-creation-screenshots";


const DeleteCourseSupport = () => {
    const steps = [
        {
            title: "Requirements",
            description: "Course deletion is only available through teacher accounts",
            imageUrl: null
        },
        {
            title: "Go to Profile",
            description: "From the home page hover over your profile icon and select 'Profile' ",
            imageUrl: "/help-and-support-images/Course-creation-screenshots/cc1.png"
        },
        {
            title: "Teacher Institution",
            description: "After opening the Profile page, on the bottom left corner of the page select 'Teacher Institution'.",
            imageUrl:  "/help-and-support-images/Course-creation-screenshots/cc2.png"
        },
        {
            title: "Manage Courses",
            description: "Select 'Manage Courses' on this page.",
            imageUrl: "/help-and-support-images/Course-creation-screenshots/cc3.png"
        },
        {
            title: "Delete course",
            description: "On the 'Manage course' page, under the 'Options' column, by clicking on the bin icon you can directly delete any course you have created.",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/del.png"
        }
    ];

    return (
        <div className="max-w-6xl mx-auto py-8">
            <h1 className="text-3xl font-bold mb-8">Deleting a Course</h1>

            <div className="space-y-8">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
                    >
                        <div className="flex flex-col md:flex-row items-center">
                            {/* Text Content */}
                            <div className="flex-1 p-6 md:pr-8 flex flex-col justify-center order-2 md:order-1">
                                <h2 className="text-xl font-semibold mb-4">
                                    Step {index + 1}: {step.title}
                                </h2>
                                <p className="text-gray-600">
                                    {step.description}
                                </p>
                            </div>

                            {/* Image only if imageUrl exists */}
                            {step.imageUrl && (
                                <div className="md:w-1/2 h-64 md:h-72 bg-gray-100 order-1 md:order-2 p-2">
                                    <div className="w-full h-full bg-white p-2 flex justify-center items-center">
                                        <img
                                            src={step.imageUrl}
                                            alt={`Step ${index + 1}: ${step.title}`}
                                            className="max-w-[90%] max-h-[90%] object-cover"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DeleteCourseSupport;