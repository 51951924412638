import React from "react";

const ShortAnswerEdit = ({ gameData }) => {
  return (
    <div>
    <div className="w-full text-center bg-zinc-50 text-zinc-300  font-smibold">
    Teacher View
  </div>
      <div className="h-[500px] flex flex-col items-start justify-start bg-zinc-100 p-4 rounded-md">
        <p className="text-sm font-semibold text-zinc-600 mb-2">Answers</p>
        <div className="flex flex-wrap gap-2 mb-4">
          {gameData.game.short_answers.map((item, i) => (
            <p
              className="px-3 py-[0.09em] text-xs font-semibold bg-black text-white rounded-full"
              key={item.Answer + i}
            >
              {i + 1}. {item.Answer}
            </p>
          ))}
        </div>
        <div className="bg-white p-3 rounded-lg w-full">
          {gameData.game.short_answers.map((item, ind) => (
            <div
              className="flex flex-col my-2 p-2 items-start justify-start w-full"
              key={item.Question + ind}
            >
              {/* Question */}
              <p className="font-semibold mb-2 w-full">{ind + 1}. {item.Question}</p>

              {/* Styled Answer Input */}
              <input
                placeholder="Type your answer here..."
                className="w-full focus:scale-[1.01] cursor-pointer focus:outline-none bg-zinc-50 border-b-2 px-2 py-1 rounded-lg"
              />
            </div>
          ))}
        </div>
      </div>
      </div>
  );
};

export default ShortAnswerEdit;
