import React from 'react';
// import {cc1, cc2, cc3, cc4, cc5, cc6, cc7, cc8, cc9} from "../Images/help-and-support-images/Course-creation-screenshots";


const EditCourseSupport = () => {
    const steps = [
        {
            title: "Requirements",
            description: "Course editing is only available through teacher accounts",
            imageUrl: null
        },
        {
            title: "Go to Profile",
            description: "From the home page hover over your profile icon and select 'Profile' ",
            imageUrl: "/help-and-support-images/Course-creation-screenshots/cc1.png"
        },
        {
            title: "Teacher Institution",
            description: "After opening the Profile page, on the bottom left corner of the page select 'Teacher Institution'.",
            imageUrl: "/help-and-support-images/Course-creation-screenshots/cc2.png"
        },
        {
            title: "Manage Courses",
            description: "Select 'Manage Courses' on this page.",
            imageUrl: "/help-and-support-images/Course-creation-screenshots/cc3.png"
        },
        {
            title: "Courses Page",
            description: "In the manage courses page, the number on the top left indicates the number of courses created. In the main section, you can view the important details regarding the courses created.",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/ecc1.png"
        },
        {
            title: "Edit each course",
            description: "On the same page, under the 'options' column, select the pen icon to directly edit the specific course.",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/ecc2.png"
        },
        {
            title: "First Impressions",
            description: "On the edit course page, under 'First impressions' on the left, you can choose to edit the course homepage and objectives.",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/ecc3.png"
        },
        {
            title: "Course Image",
            description: "On the same page at the bottom you can upload an image as the course image.",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/ecc4.png"
        },
        {
            title: "Save Changes",
            description: "On the bottom left, don't forget to click on save changes to save any changes you've made.",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/ecc5.png"
        },
        {
            title: "Course Contents",
            description: "On the same page, under 'Course Contents' on the left, selecting 'Course programme' will allow you to add reading materials/files for your course.",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/ecc6.png"
        },
        {
            title: "Add reading materials",
            description: "After clicking on 'Course Programme, you are redirected to this page. Here, you can  upload pdf files by clicking on 'Upload files', and can create multiple lessons simply by clicking on 'Add lessons'. The 'return' on the top left is to go back. Select 'save' or 'discard' to save or discard your changes here. You can also edit the lesson title and description here. ",
            imageUrl: "/help-and-support-images/Edit-delete-courses-screenshots/ecc7.png"
        }
    ];

    return (
        <div className="max-w-6xl mx-auto py-8">
            <h1 className="text-3xl font-bold mb-8">Editing an existing Course</h1>

            <div className="space-y-8">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
                    >
                        <div className="flex flex-col md:flex-row items-center">
                            {/* Text Content */}
                            <div className="flex-1 p-6 md:pr-8 flex flex-col justify-center order-2 md:order-1">
                                <h2 className="text-xl font-semibold mb-4">
                                    Step {index + 1}: {step.title}
                                </h2>
                                <p className="text-gray-600">
                                    {step.description}
                                </p>
                            </div>

                            {/* Image only if imageUrl exists */}
                            {step.imageUrl && (
                                <div className="md:w-1/2 h-64 md:h-72 bg-gray-100 order-1 md:order-2 p-2">
                                    <div className="w-full h-full bg-white p-2 flex justify-center items-center">
                                        <img
                                            src={step.imageUrl}
                                            alt={`Step ${index + 1}: ${step.title}`}
                                            className="max-w-[90%] max-h-[90%] object-cover"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EditCourseSupport;