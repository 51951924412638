import React, { useState, useEffect } from "react";
import { MdOutlineDone, MdClose, MdOutlineClose } from "react-icons/md";
import OnHoverExtraHud from "../../../components/OnHoverExtraHud";
import styles from "../../../Styles";
import { FiChevronRight } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import updateEmailProxy from "../../../BackendProxy/userProxy/updateEmailProxy"; 
import updateUsernameProxy from "../../../BackendProxy/userProxy/updateUsernameProxy";
import updateInstitutionCodeProxy from "../../../BackendProxy/userProxy/updateInstitutionCodeProxy";
import updatePasswordProxy from "../../../BackendProxy/userProxy/updatePasswordProxy";
import saveUserCookies from "../../../BackendProxy/cookiesProxy/saveUserCookies";
import { getLogedInCookies } from "../../../cookie-handler/cookieHandler";
import { updateUser } from "../../../redux/slice/user/userSlice";
import getCoursesByProp from "../../../BackendProxy/courseProxy/getCoursesByProp";
import updateCourseDataProxy from "../../../BackendProxy/courseProxy/updateCourseData";
import { MdOutlineVisibility, MdOutlineVisibilityOff } from "react-icons/md";

const SettingsProfile = () => {
  const authUser = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [user, setUser] = useState(authUser);
  const [selectedInput, setSelectedInput] = useState(null);
  const [isModified, setIsModified] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

const togglePasswordVisibility = () => {
  setShowPassword(!showPassword);
};

  useEffect(() => {
    console.log(authUser)
    setUser(authUser);
  }, [authUser]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && selectedInput) {
        handleSave();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedInput, user]);

  useEffect(() => {
    let timeout;
    if (isSuccess) {
      timeout = setTimeout(() => {
        setIsSuccess(false);
      }, 3000); // Set a 3-second timeout
    }
    return () => clearTimeout(timeout); // Cleanup timeout on unmount or state change
  }, [isSuccess]);

  const checkSelectedInput = (value) => selectedInput === value;

  const handleEmailChange = (e) => {
    setUser({ ...user, email: e.target.value });
    setIsModified(true); // Mark as modified
  };
  const handleUsernameChange = (e) => {
    setUser({ ...user, username: e.target.value });
    setIsModified(true);
  };
  const handlePasswordChange = (e) => {
    setUser({ ...user, password: e.target.value });
    setIsModified(true);
  };

  const handleUsernameUpdate = async () => {
    try {
      // Update the username in the User document
      await updateUsernameProxy(user._id, user.username);
      console.log("Username updated in DB to:", user.username);
  
      // Update Redux and cookies with the new username
      dispatch(updateUser({ username: user.username }));

      saveUserCookies({ ...authUser, username: user.username });
  
      if (authUser.accountType === "instructor") {
        const response = await getCoursesByProp("creator.username", authUser.username, authUser.institution.code);
        const courses = response.res;
  
        if (courses && courses.length > 0) {
          await Promise.all(
            courses.map(async (course) => {
              // Update each course's creator.username field
              const courseData = {
                _id: course._id,
                creator: {
                  ...course.creator,
                  username: user.username, // Update username
                },
              };
              await updateCourseDataProxy(courseData);
            })
          );
          console.log("Courses updated with the new instructor username.");
        } else {
          console.log("No courses found for this instructor.");
        }
      }
      setSelectedInput(null); 
      setIsSuccess(true);
      setIsModified(false);
    } catch (error) {
      console.error("Failed to update username:", error);
    }
  };
  const handleInstitutionCodeUpdate = async () => {
    try {
      await updateInstitutionCodeProxy(user._id, user.institutionCode);
      console.log("Institution Code updated in DB to:", user.institutionCode);

      dispatch(updateUser({ institutionCode: user.institutionCode }));
      saveUserCookies({ ...authUser, institutionCode: user.institutionCode }); 

      setSelectedInput(null);
      setIsSuccess(true);
      setIsModified(false);
    } catch (error) {
      console.error("Failed to update institution code:", error);
    }
  };

  const handleEmailUpdate = async () => {
    try {
      await updateEmailProxy(user._id, user.email);
      console.log("Email updated in DB to:", user.email);

      dispatch(updateUser({ email: user.email }));
      saveUserCookies({ ...authUser, email: user.email }); 

      setSelectedInput(null);
      setIsSuccess(true);
      setIsModified(false);
    } catch (error) {
      console.error("Failed to update email:", error);
    }
  };

  const handlePasswordUpdate = async () => {
    try {
      await updatePasswordProxy(user._id, user.password);
      console.log("Password updated successfully");

      setSelectedInput(null);
      setIsSuccess(true);
      setIsModified(false);
    } catch (error) {
      console.error("Failed to update password:", error);
    }
  };

  const handleSave = async () => {
    try {
      if (checkSelectedInput("email")) await handleEmailUpdate();
      if (checkSelectedInput("username")) await handleUsernameUpdate();
      if (checkSelectedInput("institutionCode")) await handleInstitutionCodeUpdate();
      if (checkSelectedInput("password")) await handlePasswordUpdate();

      setSelectedInput(null);
    } catch (error) {
      console.error("Failed to update user data:", error);
    }
  };

  const handleDiscard = () => {
    setUser(authUser);
    setSelectedInput(null);
    setIsModified(false); // Reset modification status
  };

  return (
    <>
      <div className="bg-white rounded-full flex justify-between items-center py-2 px-4">
        <p className="font-semibold text-lg">Settings</p>
        <div className="flex space-x-1">
          <div
            onClick={handleSave} 
            className={`flex items-center space-x-3 bg-green-400 w-[30px] h-[30px] justify-center rounded-full cursor-pointer hover-parent ${!selectedInput ? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <p className="text-lg font-semibold text-green-100">
              <OnHoverExtraHud name="save" />
              <MdOutlineDone />
            </p>
          </div>
          <div
            onClick={handleDiscard}
            className={`flex items-center space-x-3 bg-red-400 w-[30px] h-[30px] justify-center rounded-full cursor-pointer hover-parent ${!selectedInput? 'opacity-50 cursor-not-allowed' : ''}`}
          >
            <p className="text-lg font-semibold text-red-200">
              <OnHoverExtraHud name="discard" />
              <MdClose />
            </p>
          </div>
        </div>
      </div>
      <p className="mt-4 font-bold text-xl">User Information</p>
      <div className="p-8 bg-white rounded-lg mt-4 w-full no-select">
        <table className="w-full table-auto rounded-lg overflow-hidden ">
          <tbody>
          {authUser.accountType !== "student" && (
  <>
  
  <tr className="border-b">
  <td className="p-4">
    <label
      htmlFor="username"
      className="font-semibold text-lg text-gray-700 cursor-default"
    >
      <span>Username</span>
    </label>
  </td>
  <td className="p-4 text-end">
    {checkSelectedInput("username") ? (
      <div className="flex items-center justify-end space-x-2">
        <input
          value={user.username}
          onChange={handleUsernameChange}
          id="username"
          placeholder="Username"
          type="text"
          className="w-64 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
          autoComplete="off"
        />
        <MdOutlineClose
          onClick={() => setSelectedInput(null)}
          className="text-stone-500 cursor-pointer"
        />
      </div>
    ) : (
      <div className="flex items-center justify-end space-x-2">
        <p className="text-sm text-gray-700">{user.username}</p>
        <div
          onClick={() => setSelectedInput("username")}
          className="hover:bg-gray-100 p-2 rounded-full transition-all cursor-pointer"
        >
          <OnHoverExtraHud name="edit" />
          <FiChevronRight className="text-gray-500" />
        </div>
      </div>
    )}
  </td>
</tr>

<tr className="border-b">
  <td className="p-4">
    <label
      htmlFor="email"
      className="font-semibold text-lg text-gray-700 cursor-default"
    >
      Email
    </label>
  </td>
  <td className="p-4 text-end">
    {checkSelectedInput("email") ? (
      <div className="flex items-center justify-end space-x-2">
        <input
          value={user.email}
          onChange={handleEmailChange}
          id="email"
          placeholder="Email"
          type="text"
          className="w-64 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
          autoComplete="off"
        />
        <MdOutlineClose
          onClick={() => setSelectedInput(null)}
          className="text-stone-500 cursor-pointer"
        />
      </div>
    ) : (
      <div className="flex items-center justify-end space-x-2">
        <p className="text-sm text-gray-700">{user.email}</p>
        <div
          onClick={() => setSelectedInput("email")}
          className="hover:bg-gray-100 p-2 rounded-full transition-all cursor-pointer"
        >
          <OnHoverExtraHud name="edit" />
          <FiChevronRight className="text-gray-500" />
        </div>
      </div>
    )}
  </td>
</tr>
  </>
)}
<tr>
  <td className="p-4">
    <label
      htmlFor="password"
      className="font-semibold text-lg text-gray-700 cursor-default"
    >
      Password
    </label>
  </td>
  <td className="p-4 text-end">
    {checkSelectedInput("password") ? (
      <div className="flex items-center justify-end space-x-2">
        <input
          id="password"
          onChange={handlePasswordChange}
          placeholder="Password"
          type={showPassword ? "text" : "password"}
          className="w-64 p-2 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
          autoComplete="new-password"
        />
        <div onClick={togglePasswordVisibility} className="cursor-pointer">
          {showPassword ? (
            <MdOutlineVisibility className="text-gray-600" />
          ) : (
            <MdOutlineVisibilityOff className="text-gray-600" />
          )}
        </div>
        <MdOutlineClose
          onClick={() => setSelectedInput(null)}
          className="text-stone-500 cursor-pointer"
        />
      </div>
    ) : (
      <div className="flex items-center justify-end space-x-2">
        <p className="text-sm text-gray-700">**********</p>
        <div
          onClick={() => setSelectedInput("password")}
          className="hover:bg-gray-100 p-2 rounded-full transition-all cursor-pointer"
        >
          <OnHoverExtraHud name="edit" />
          <FiChevronRight className="text-gray-500" />
        </div>
      </div>
    )}
  </td>
</tr>


        
          </tbody>
        </table>
      </div>
      {isSuccess && (
        <div className="mt-4 p-4 bg-green-200 rounded-md shadow-md w-full">
          <p className="text-green-700 font-semibold text-center">
            Update Successful!
          </p>
        </div>
      )}
    </>
  );
};

export default SettingsProfile;
