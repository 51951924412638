import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GeneralNavbar from "../../../../components/navbar/GeneralNavbar";
import BlobComposition from "../../../../components/backgrounds/BlobComposition/BlobComposition";
import { IoMdSearch } from "react-icons/io";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { FiBell } from "react-icons/fi";
import { useSelector } from "react-redux";
import OnHoverExtraHud from "../../../../components/OnHoverExtraHud";
import Pagination from "../../Profile/Pagination";
import {getTeachers} from "../../../../BackendProxy/adminProxy/getTeachers";
import DialogBox from "../../dialog/DialogBox";
import SpinnerLoader from "../../../../components/loaders/SpinnerLoader";

const AdminManageEducators = () => {
  const authUser = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [teachers, setTeachers] = useState([]);
  const [filteredTeachers, setFilteredTeachers] = useState([]); 
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectAll, setSelectAll] = useState(false);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const [initialLoading, setInitialLoading] = useState(true);


   const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [currentMessage, setCurrentMessage] = useState("");
    
    const openDialog = (message) => {
      setCurrentMessage(message);
      setIsDialogOpen(true);
    };

    const handleSearchChange = (e) => {
      setSearchTerm(e.target.value);
      setCurrentPage(1);  
      fetchTeachers(1);   
    };

 
    const fetchTeachers = async (page = currentPage) => {
      try {
        if (initialLoading) {
          setLoading(true);  
        }
        const res = await getTeachers({
          code: authUser.institution.code,
          searchTerm,
          page,
          limit: itemsPerPage,
        });
        setTeachers(res.data);
        setTotalPages(res.totalPages);
        setCurrentPage(page); 
        setLoading(false);
        setInitialLoading(false);
      } catch (error) {
        console.error("Error fetching teachers:", error);
        setLoading(false);
        setInitialLoading(false);
      }
    };

    useEffect(() => {
      fetchTeachers(currentPage);
    }, [authUser, currentPage, searchTerm]);
  

  const handleCheckboxChange = (teacherId) => {
    setSelectedTeachers((prev) =>
      prev.includes(teacherId)
        ? prev.filter((id) => id !== teacherId) // Remove from selection
        : [...prev, teacherId] // Add to selection
    );
  };

  const toggleSelectAll = () => {
    const currentPageTeacherIds = teachers.map((teacher) => teacher._id);
    const allSelected = currentPageTeacherIds.every((id) =>
      selectedTeachers.includes(id)
    );

    if (allSelected) {
      setSelectedTeachers((prev) =>
        prev.filter((id) => !currentPageTeacherIds.includes(id))
      );
    } else {
      setSelectedTeachers((prev) => [...new Set([...prev, ...currentPageTeacherIds])]);
    }
    setSelectAll(!allSelected);
  };

  useEffect(() => {
    const currentPageTeacherIds = teachers.map((teacher) => teacher._id);
    const allSelected = currentPageTeacherIds.every((id) =>
      selectedTeachers.includes(id)
    );
    setSelectAll(allSelected);
  }, [selectedTeachers, teachers]);

  const handleSendBulkNotification = () => {
    if (selectedTeachers.length === 0) {
      openDialog("No teachers selected for notification.");
      return;
    }
    navigate("/admin/send-notification-teachers", {
      state: { teacherIds: selectedTeachers, sender: authUser.username },
    });
  };

  return (
    <div>
      <GeneralNavbar />
      <BlobComposition
        blobsData={[
          { top: "10%", left: "-20%", size: "700px" },
          { top: "-30%", left: "70%", size: "400px" },
          { top: "40%", left: "50%", size: "300px" },
        ]}
      />
      <div className="m-auto max-w-[1200px] mt-3 min-h-[100vh]">
        <div className="bg-white rounded-full flex justify-between items-center py-2 px-4">
          <p className="font-semibold text-lg">Manage Educators</p>
          <div className="flex items-center space-x-3">
            <div className="flex items-center">
              <input
                placeholder="Search by name"
                className="text-sm focus:outline-none focus:border-b-stone-400 border-b-transparent border-b-[1.5px] pr-2 py-1 font-medium text-stone-600"
                value={searchTerm}
                onChange={handleSearchChange} 
              />
              <IoMdSearch />
            </div>
          </div>
        </div>

        <div className="my-2 flex items-center justify-between">
          {/* Invite Educators button aligned to the left */}
          <button
            onClick={() => navigate("/admin/invite/educators")}
            className="text-white font-medium px-3 py-1 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
          >
            + Invite Educators
          </button>

          {/* Send Notification button aligned to the right */}
          <button
            onClick={handleSendBulkNotification}
            className="text-white font-medium px-3 py-1 ml-3 rounded-full linearGradient_ver1 text-sm hover:scale-[1.05] transition-all"
          >
            Send Notification
          </button>
        </div>

        <div className="bg-white py-2 px-4 mt-2 rounded-lg">
          <table className="table-auto w-full">
            <thead>
              <tr>
                <th>
                {teachers.length > 0 && (
        <input
          type="checkbox"
          checked={
            teachers.length > 0 &&
           teachers.every((teacher) => selectedTeachers.includes(teacher._id))
          }
          onChange={toggleSelectAll}
          className="w-5 h-5"
        />
      )}
                </th>
                <th>Username</th>
                <th>Email</th>
                <th className="text-end">Options</th>
              </tr>
            </thead>
            {initialLoading  ? (
          <SpinnerLoader />
        ) : (
            <tbody>
          
              {teachers.map((teacher) => (
                <TeacherCard
                  key={teacher._id}
                  teacher={teacher}
                  handleCheckboxChange={handleCheckboxChange}
                  isChecked={selectedTeachers.includes(teacher._id)}
                />
              ))}
            </tbody>
        )}
          </table>
          {teachers.length > 0 && (
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
          {teachers.length === 0 && (
            <div className="text-center py-4 text-gray-500">
              No teachers available.
            </div>
          )}
        </div>
        <DialogBox
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        message={currentMessage}
      />
      </div>
    </div>
  );
};

const TeacherCard = ({ teacher, handleCheckboxChange, isChecked }) => {
  const navigate = useNavigate();
  const rowStyle = isChecked ? { backgroundColor: "#b2d8d8" } : { backgroundColor: "white" };

  const handleSendNotification = () => {
    navigate("/admin/send-notification-teachers", {
      state: { teacherIds: [teacher._id] },
    });
  };

  return (
    <tr style={rowStyle}>
      <td>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={() => handleCheckboxChange(teacher._id)}
          className="w-5 h-5"
        />
      </td>
      <td>{teacher.username}</td>
      <td>{teacher.email}</td>
      <td className="flex items-center justify-end space-x-4">
        <button
          onClick={handleSendNotification}
          className="p-2 rounded-full bg-yellow-100 hover:bg-yellow-200 transition-all cursor-pointer flex items-center justify-center relative focus:outline-none hover-parent"
          aria-label="Send Notification"
        >
          <FiBell className="text-yellow-600" />
          <OnHoverExtraHud name="Send Notification" />
        </button>
      </td>
    </tr>
  );
};

export default AdminManageEducators;
