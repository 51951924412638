import React from 'react';


const StudentAccountSupport = () => {
    const steps = [
        {
            title: "Choose your account type",
            description: "Select 'Student' since a student account is being created.",
            imageUrl: "/help-and-support-images/account-creation/ChooseAccStudent.png"
        },
        {
            title: "Enter Your Details",
            description: "Type your First name, Lastname, Email, Username. If you belong to an institution, you must have an institution Id which you can also enter by toggling the 'Are you linked to an institution'.",
            imageUrl: null // No image for this step
        },
        {
            title: "Enter Your Password",
            description: "Finally enter your password and confirm the same."
        },
        {
            title: "Create Account",
            description: "Finally, select 'Create Account,' and your account will be successfully created!",
            imageUrl: null
        },
        {
            title: "Google Sign-in",
            description: "Alternatively, you can also use your Google account to sign up",
            imageUrl: "/help-and-support-images/account-creation/GoogleSignIn.png"
        }
    ];

    return (
        <div className="max-w-6xl mx-auto py-8">
            <h1 className="text-3xl font-bold mb-8">How to Sign In</h1>

            <div className="space-y-8">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
                    >
                        <div className="flex flex-col md:flex-row items-center">
                            {/* Text Content */}
                            <div className="flex-1 p-6 md:pr-8 flex flex-col justify-center order-2 md:order-1">
                                <h2 className="text-xl font-semibold mb-4">
                                    Step {index + 1}: {step.title}
                                </h2>
                                <p className="text-gray-600">
                                    {step.description}
                                </p>
                            </div>

                            {/* Image only if imageUrl exists */}
                            {step.imageUrl && (
                                <div className="md:w-1/2 h-64 md:h-72 bg-gray-100 order-1 md:order-2 p-2">
                                    <div className="w-full h-full bg-white p-2 flex justify-center items-center">
                                        <img
                                            src={step.imageUrl}
                                            alt={`Step ${index + 1}: ${step.title}`}
                                            className="max-w-[90%] max-h-[90%] object-cover"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StudentAccountSupport;
