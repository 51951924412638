import React from 'react';


const StudentProfileSupport = () => {
    const steps = [
        {
            title: "Home Page Overview",
            description: "You can search for different courses in the search bar. On the top right, the heart and bell icon indicates your wishlist and your notifications respectively. Under 'Discover our course offerings', you can find multiple courses available. Hovering over your profile icon on the top right of the page and clicking on 'profile' will take you to the next page.",
            imageUrl: "/help-and-support-images/profile-ss/sp1.png"
        },
        {
            title: "Profile page",
            description: "On the left of the page is the sidebar. Click on 'My courses' to view all courses created for you.",
            imageUrl: "/help-and-support-images/profile-ss/sp2.png"
        },
        {
            title: "Courses Created",
            description: "Here you can view the different courses created for you. You can access the course from here as well as directly from the homepage.",
            imageUrl: "/help-and-support-images/profile-ss/sp3.png"
        },
        {
            title: "Notifications",
            description: "Here you van view the different notifications recieved. You can read or delete them here.",
            imageUrl: "/help-and-support-images/profile-ss/sp4.png"
        },
        {
            title: "Settings",
            description: "Here you can view your account details.",
            imageUrl: "/help-and-support-images/profile-ss/sp5.png"
        },
        {
            title: "Edit profile Information",
            description: "Here you can click on the right arrow at the end of each field to edit the respective field. Click on the 'tick' or 'cross' symbol at the top right to update or delete the changes to the profile information.",
            imageUrl: "/help-and-support-images/profile-ss/sp6.png"
        }
    ];

    return (
        <div className="max-w-6xl mx-auto py-8">
            <h1 className="text-3xl font-bold mb-8">How to Sign In</h1>

            <div className="space-y-8">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className="bg-white rounded-lg shadow-md overflow-hidden border border-gray-200"
                    >
                        <div className="flex flex-col md:flex-row items-center">
                            {/* Text Content */}
                            <div className="flex-1 p-6 md:pr-8 flex flex-col justify-center order-2 md:order-1">
                                <h2 className="text-xl font-semibold mb-4">
                                    Step {index + 1}: {step.title}
                                </h2>
                                <p className="text-gray-600">
                                    {step.description}
                                </p>
                            </div>

                            {/* Image only if imageUrl exists */}
                            {step.imageUrl && (
                                <div className="md:w-1/2 h-64 md:h-72 bg-gray-100 order-1 md:order-2 p-2">
                                    <div className="w-full h-full bg-white p-2 flex justify-center items-center">
                                        <img
                                            src={step.imageUrl}
                                            alt={`Step ${index + 1}: ${step.title}`}
                                            className="max-w-[90%] max-h-[90%] object-cover"
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StudentProfileSupport;
