import React, { useState, useEffect } from "react";
import { FaCheckCircle } from "react-icons/fa";
import updateGrades from "../../../BackendProxy/courseProxy/updateGrades";

const ShortAnswersPlayable = ({ gameData, onNextLesson, isLastLesson, enrollment, selectedLesson }) => {
  const lessonId = selectedLesson._id;
  const lessonTitle = selectedLesson.title;
  const [completed, setCompleted] = useState(false);
  const [failed, setFailed] = useState(false);
  const [answersObj, setAnswersObj] = useState(() => {
    const data = gameData.game.short_answers.map((element) => {
      return { ...element, current: "" };
    });
    return data;
  });

  const updateAnswerCurrent = (index, newValue) => {
    setAnswersObj((prevAnswers) =>
      prevAnswers.map((answer, i) =>
        i === index ? { ...answer, current: newValue } : answer
      )
    );
  };

  const checkValues = () => {
    let correctCount = 0;
    const updatedAnswers = answersObj.map((element) => {
      if (element.current.toUpperCase() === element.Answer.toUpperCase()) {
        correctCount++;
        return { ...element, correct: true };
      } else {
        return { ...element, correct: false };
      }
    });

    setAnswersObj(updatedAnswers);
    return correctCount;
  };

  const handleCompletionCheck = () => {
    const correctCount = checkValues();
    const percentage = (correctCount / answersObj.length) * 100;

    if (percentage >= 80) {
      setCompleted(true);
      setFailed(false);
    } else {
      setFailed(true);
      setCompleted(false);
    }
  };

  const saveGradeToBackend = async (lessonId, lessonTitle, percentage) => {
    try {
      await updateGrades(enrollment._id, lessonId, lessonTitle, percentage);
      console.log("Grade saved successfully!");
    } catch (error) {
      console.error("Error saving grade:", error);
    }
  };

  const handleNextLesson = async () => {
    if (completed) {
      const correctCount = checkValues();
      const percentage = (correctCount / answersObj.length) * 100;

      await saveGradeToBackend(lessonId, lessonTitle, percentage);
      onNextLesson();
    }
  };

  useEffect(() => {
    console.log(answersObj);
  }, [answersObj]);

  return (
    <div className="w-full relative">
      {/* Completion Overlay */}
      {completed && (
        <div className="absolute h-full w-full bg-[#0005] z-30 rounded-lg flex flex-col items-center justify-center">
          <FaCheckCircle className="text-3xl text-white" />
          <p className="mt-1 font-bold text-white">COMPLETED!</p>
          <button
            onClick={handleNextLesson}
            className="linearGradient_ver1 px-3 rounded-full font-semibold text-white hover:scale-[1.01] transition-all flex items-center justify-center mt-4"
          >
            {isLastLesson ? "Complete Course" : "Next"}
          </button>
        </div>
      )}

      {/* Failure Message */}
      {!completed && failed && (
        <div className="text-red-500 font-semibold mb-2">
          <p>Not enough correct answers. You need at least 80% correct to pass. Please try again!</p>
        </div>
      )}

      {/* Short Answer Questions */}
      <div className="bg-white p-3 rounded-lg">
        {answersObj.map((item, ind) => (
          <div className="mb-4" key={item.Answer + ind}>
            {/* Question */}
            <p className="text-gray-800 font-semibold mb-2">{ind + 1}. {item.Question}</p>

            {/* Styled Answer Input */}
            <input
              onChange={(e) => updateAnswerCurrent(ind, e.target.value)}
              value={item.current}
              placeholder="Type your answer here..."
              className={`${item.correct ? 'bg-green-100' : 'bg-zinc-50'} w-full cursor-pointer focus:outline-none border border-gray-300 px-3 py-2 rounded-md`}
            />
          </div>
        ))}
      </div>

      <div className="mt-4 flex justify-start">
        <button
          onClick={handleCompletionCheck}
          className="linearGradient_ver1 px-4 py-2 ml-2 rounded-full font-semibold text-white hover:scale-[1.03] transition-all"
        >
          Check Answers
        </button>
      </div>
    </div>
  );
};

export default ShortAnswersPlayable;
