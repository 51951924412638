import React, { useEffect } from "react";
import { Dialog } from "@headlessui/react";
 
const ConfirmationDialog = ({ isOpen, onClose, onConfirm, message }) => {

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter" && isOpen) {
        onConfirm();
        onClose();
      }
    };

    if (isOpen) {
      window.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, onClose, onConfirm]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
    >
      <Dialog.Panel className="w-full max-w-md p-6 bg-white rounded shadow-lg">
        <Dialog.Title className="text-lg font-bold text-gray-900">
          Confirmation
        </Dialog.Title>
        <Dialog.Description className="mt-2 text-sm text-gray-700 text-center">
          {message}
        </Dialog.Description>
        <div className="mt-4 flex justify-between">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-600 bg-gray-200 rounded hover:bg-gray-300"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onConfirm();
              onClose();
            }}
            className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded hover:bg-red-700"
          >
            Confirm
          </button>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
};

export default ConfirmationDialog;
