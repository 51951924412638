import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { jsPDF } from 'jspdf';


const VerifyCertificate = () => {
  const { certificateId } = useParams(); // Extract certificateId from URL
  const [certificate, setCertificate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCertificate = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/certificate/verify/${certificateId}`
        );

        if (response.data.success) {
          setCertificate(response.data.certificate); // Set the certificate data
        } else {
          setError("Certificate not found.");
        }
      } catch (err) {
        setError("Error fetching certificate details.");
      } finally {
        setLoading(false);
      }
    };

    fetchCertificate();
  }, [certificateId]);

  const generateCertificate = async (certificate) => {  
    // Generate the certificate PDF
    const doc = new jsPDF("landscape"); // Set orientation to landscape
  
    // Set up colors for background elements
    const primaryColor = [70, 130, 180]; // Steel blue for title and main text
    const lightColor = [220, 230, 240]; // Light blue for background design
  
    // Background rectangle
    doc.setFillColor(...lightColor);
    doc.rect(10, 10, 270, 180, "F"); // Light background rectangle to frame content
  
    // Decorative circle on the left
    doc.setFillColor(...primaryColor);
    doc.circle(30, 30, 20, "F"); // Filled circle for a modern look
  
    // Title text
    doc.setFontSize(30);
    doc.setFont("times", "bold");
    doc.setTextColor(...primaryColor);
    doc.text("Certificate of Completion", 148, 50, { align: "center" });
  
    // Subtitle
    doc.setFontSize(14);
    doc.setFont("helvetica", "italic");
    doc.setTextColor(60, 60, 60); // Dark gray
    doc.text("This certifies that", 148, 70, { align: "center" });
  
    // User's name in large font
    doc.setFontSize(24);
    doc.setFont("times", "bold");
    doc.setTextColor(...primaryColor);
    doc.text(certificate.username, 148, 90, { align: "center" });
  
    // Course completion text
    doc.setFontSize(14);
    doc.setFont("helvetica", "italic");
    doc.setTextColor(60, 60, 60);
    doc.text(`has successfully completed the course:`, 148, 110, { align: "center" });
  
    // Course name in bold
    doc.setFontSize(18);
    doc.setFont("times", "bold");
    doc.setTextColor(...primaryColor);
    doc.text(certificate.courseName, 148, 130, { align: "center" });
  
    // Date and organization text
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.text(`Date: ${certificate.dateIssued}`, 30, 160); // Date at the bottom left
    doc.text("Certified by: PHC Institute", 250, 160, { align: "right" });
  
    // Save the generated PDF
    doc.save(`Certificate_${certificate.username}_${certificate.courseName}.pdf`);
  };
  

  const handleBack = () => {
    navigate("/home");
  };

  if (loading) return <div>Loading certificate details...</div>;

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      {error ? (
        <div style={{ color: "red" }}>
          <h1>Error</h1>
          <p>{error}</p>
        </div>
      ) : (
        <div
          style={{
            fontFamily: "'Times New Roman', Times, serif",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
            backgroundColor: "#dce6f0", // Light blue
            position: "relative",
          }}
        >
          {/* Back Button */}
<button
  onClick={handleBack}

  style={{
    position: "absolute",
    top: "20px",
    left: "20px",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "transparent",
    color: "#f44336",
    border: "2px solid #f44336",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
    width: "50px",
    height: "50px",
    justifyContent: "center",
  }}
  title="Back"
>
  <span
    style={{
      fontSize: "24px",
      fontWeight: "bold",
    }}
  >
    ←
  </span>
</button>

{/* Download Button with Label */}
<div
  style={{
    position: "absolute",
    top: "20px",
    right: "20px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
  }}
>
<button
  onClick={() => generateCertificate(certificate)}
  style={{
    position: "absolute",
    top: "20px",
    right: "20px",
    display: "flex",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "transparent",
    color: "#4CAF50",
    border: "2px solid #4CAF50",
    borderRadius: "50%",
    cursor: "pointer",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
    width: "50px",
    height: "50px",
    justifyContent: "center",
  }}
  title="Download"
>
  <span
    style={{
      fontSize: "24px",
      fontWeight: "bold",
    }}
  >
    ↓
  </span>
</button>

<span
    style={{
      fontSize: "14px",
      color: "#4CAF50",
      fontWeight: "300",
      textAlign: "center",
      letterSpacing: "0.5px",
    }}
  >
    Download PDF
  </span>
  </div>

          <div
            style={{
              position: "relative",
              width: "90%",
              maxWidth: "1000px",
              height: "600px",
              backgroundColor: "white",
              border: "5px solid #4682b4", // Steel blue border
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {/* Decorative Circle */}
            <div
              style={{
                position: "absolute",
                top: "20px",
                left: "20px",
                width: "50px",
                height: "50px",
                backgroundColor: "#4682b4", // Steel blue
                borderRadius: "50%",
              }}
            ></div>

            {/* Title */}
            <h1
              style={{
                textAlign: "center",
                fontSize: "30px",
                color: "#4682b4", // Steel blue
                fontWeight: "bold",
              }}
            >
              Certificate of Completion
            </h1>

            {/* Subtitle */}
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "italic",
                color: "#3c3c3c", // Dark gray
              }}
            >
              This certifies that
            </p>

            {/* User Name */}
            <h2
              style={{
                textAlign: "center",
                fontSize: "24px",
                color: "#4682b4", // Steel blue
                fontWeight: "bold",
              }}
            >
              {certificate.username}
            </h2>

            {/* Course Completion Text */}
            <p
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontStyle: "italic",
                color: "#3c3c3c",
              }}
            >
              has successfully completed the course:
            </p>

            {/* Course Name */}
            <h3
              style={{
                textAlign: "center",
                fontSize: "18px",
                color: "#4682b4", // Steel blue
                fontWeight: "bold",
              }}
            >
              {certificate.courseName}
            </h3>

            {/* Date and Certified By */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "0 20px",
              }}
            >
              <p style={{ fontSize: "12px" }}>Date: {certificate.dateIssued}</p>
              <p style={{ fontSize: "12px", textAlign: "right" }}>
                Certified by: PHC Institute
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerifyCertificate;


