import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL + 'api/admin';

const getStudents = async (code, page = 1, limit = 10, searchTerm = "", selectedCourse = "") => {
  try {
    const response = await axios.post(`${BASE_URL}/get-students`, {
      code,
      page,
      limit,
      searchTerm,
      selectedCourse,
    });
    return response.data; 
  } catch (error) {
    console.error(error);
    throw error;
  }
};


export default getStudents;
