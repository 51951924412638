import React, { useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import GeneralNavbar from '../components/navbar/GeneralNavbar';
import CreateAccountSupport from './CreateAccountSupport';
import TeacherAccountSupport from './TeacherAccountSupport';
import StudentAccountSupport from './StudentAccountSupport';
import NewCourseDocSupport from './NewCourseDocSupport';
import EditCourseSupport from './EditCourseSupport';
import DeleteCourseSupport from './DeleteCourseSupport';
import ManageStudentsSupport from './ManageStudentsSupport';
import StudentProfileSupport from './StudentProfileSupport';
import TeacherProfileSupport from './TeacherProfileSupport';

const SupportLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState('overview');
  const [openMenu, setOpenMenu] = useState(null);

  const sidebarItems = [
    { id: 'overview', title: 'Overview and help guide', path: '/support' },
    {
      id: 'create', title: 'Create a new account', path: '/support/account',
      subcategories: [
        { id: 'student', title: 'Student Account', path: '/support/account/studentAcc' },
        { id: 'teacher', title: 'Teacher Account', path: '/support/account/teacherAcc' },
        { id: 'admin', title: 'Admin Account', path: '/support/account/adminAcc' },
      ],
    },
    { id: 'account-overview', title: 'Account overview', path: '/support/account-overview',
      subcategories: [
        { id: 'student', title: 'Student Account', path: '/support/account-overview/student'},
        { id: 'teacher', title: 'Teacher Account', path: '/support/account-overview/teacher'}
      ],
     },
    { id: 'manage-students', title: 'Managing Students', path: '/support/manage-students' },
    { id: 'new-course', title: "Creating a new Course", path: '/support/new-course-doc' },
    { id: 'edit-course', title: "Edit a Course", path: '/support/edit-course-doc' },
    { id: 'delete-course', title: "Delete a Course", path: '/support/delete-course-doc' },
    // { id: 'password', title: 'Password reset and recovery', path: '/support/password' },
    // { id: 'username', title: 'Forgot username', path: '/support/username' },
  ];

  const toggleMenu = (menuId) => {
    setOpenMenu((prev) => (prev === menuId ? null : menuId)); // Toggle the clicked menu
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <GeneralNavbar fixed={true} />
      <div className="pt-16"> 
      <div className="flex">
        <div className="w-64 bg-white shadow-sm min-h-screen fixed left-0 top-16 overflow-y-auto">
          <div className="p-4 border-b bg-pink-700">
            <h2 className="text-xl font-semibold text-white">Lotus account help</h2>
          </div>
            <nav className="p-2">
              {sidebarItems.map((item) => (
                <React.Fragment key={item.id}>
                  <button
                    onClick={() => {
                      if (item.subcategories) toggleMenu(item.id);
                      else navigate(item.path);
                    }
                    }
                    className={`w-full text-left p-3 rounded text-sm mb-1 transition-colors
          ${location.pathname === item.path
                        ? 'bg-pink-50 text-pink-700 font-medium'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-pink-700'}`}
                  >
                    {item.title}
                  </button>
                  {item.subcategories && openMenu === item.id && (
                    <div className="ml-4">
                      {item.subcategories.map((subItem) => (
                        <button
                          key={subItem.id}
                          onClick={() => navigate(subItem.path)}
                          className={`w-full text-left p-2 rounded text-sm mb-1 transition-colors ${location.pathname === subItem.path
                            ? 'bg-pink-100 text-pink-700 font-medium'
                            : 'text-gray-700 hover:bg-gray-50 hover:text-pink-700'
                            }`}
                        >
                          {subItem.title}
                        </button>
                      ))}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </nav>

          </div>

          {/* Main Content Area */}
          <div className="ml-64 flex-1 p-8 bg-white min-h-screen">
            <Routes>
              <Route path="/" element={<Overview />} />
              <Route path="/overview" element={<Overview />} />
              <Route path='/account' element={<CreateAccountSupport />} />
              <Route path='/account/studentAcc' element={<StudentAccountSupport />} />
              <Route path='/account/teacherAcc' element={<TeacherAccountSupport />} />
              <Route path="/account-overview" element={<AccountOverview />} />
              <Route path="/account-overview/teacher" element={<TeacherProfileSupport/>}/>
              <Route path="/account-overview/student" element={<StudentProfileSupport/>}/>
              <Route path="/manage-students" element={<ManageStudentsSupport />} />
              <Route path="/new-course-doc" element={<NewCourseDocSupport />}/>
              <Route path="/edit-course-doc" element={<EditCourseSupport />}/>
              <Route path="/delete-course-doc" element={<DeleteCourseSupport />}/>
              {/* Add other routes */}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

// Example of styled page component
const Overview = () => {
  return (
    <div className="max-w-auto">
      <h1 className="text-3xl font-semibold mb-6 text-gray-900 ml-4">Overview and Help Guide</h1>
      <div className="bg-white rounded-lg p-6 shadow-sm">
        <p className="text-gray-600 mb-6">
          Welcome to the Lotus Learning help center. Find everything you need to make the most of your learning experience.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="border rounded-lg p-4">
            <h3 className="text-lg font-medium text-pink-700 mb-3">Account Management</h3>
            <ul className="space-y-2 text-gray-600">
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-pink-700 rounded-full mr-2"></span>
                Managing your profile
              </li>
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-pink-700 rounded-full mr-2"></span>
                Security settings
              </li>
            </ul>
          </div>

          <div className="border rounded-lg p-4">
            <h3 className="text-lg font-medium text-pink-700 mb-3">Course Access</h3>
            <ul className="space-y-2 text-gray-600">
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-pink-700 rounded-full mr-2"></span>
                Accessing your courses
              </li>
              <li className="flex items-center">
                <span className="w-1.5 h-1.5 bg-pink-700 rounded-full mr-2"></span>
                Technical requirements
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 p-4 bg-pink-50 rounded-lg">
          <h3 className="text-lg font-medium text-pink-700 mb-2">Need Additional Help?</h3>
          <p className="text-gray-600 mb-4">Our support team is here to assist you with any questions.</p>
          <button className="bg-pink-700 text-white px-4 py-2 rounded hover:bg-pink-800 transition-colors">
            Contact Support
          </button>
        </div>
      </div>
    </div>
  );
};

const AccountOverview = () => {
  return (
    <div className="max-w-4xl">
      <h1 className="text-3xl font-semibold mb-6 text-gray-900">Account Overview</h1>
      <div className="bg-white rounded-lg p-6 shadow-sm">
        
      </div>
    </div>
  );
};

// const CreateAccount = () => {
//   return (
//     <div className="max-w-4xl">
//       <h1 className="text-3xl font-semibold mb-6 text-gray-900">Account Creation</h1>
//       <div className="bg-white rounded-lg p-6 shadow-sm">
//         {/* Add content with similar styling */}
//       </div>
//     </div>
//   );
// };

// const ManageStudents = () => {
//   return (
//     <div className="max-w-4xl">
//       <h1 className="text-3xl font-semibold mb-6 text-gray-900">Manage Students</h1>
//       <div className="bg-white rounded-lg p-6 shadow-sm">
//         {/* Add content with similar styling */}
//       </div>
//     </div>
//   );
// };

export default SupportLayout;